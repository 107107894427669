import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { setLoading } from '../../store/slices/auth';
import { expectedDateFormat } from '../../utils/Utils';
import { showToaster, toasterTypes } from '../../widgets';
import AddressDetails from './AddressDetails';
import { deleteAward, deleteEducation, deleteResearchExperience, deleteResearchProfile, deleteResearchWork, deleteSkillSet, deleteWorkExperience, getProfileDetails } from './apiController';
import Awards from './Awards';
import ContactDetails from './ContactDetails';
import EducationDetails from './EducationDetails';
import ProfileDetails from './ProfileDetails';
import PublicationDetails from './PublicationDetails';
import ResearchProfile from './ResearchProfile';
import ResearchWork from './ResearchWork';
import SkillSet from './SkillSet';
import WorkExperience from './WorkExperience';
import dayjs from 'dayjs';
import { LinkOutlined, SnippetsOutlined } from '@ant-design/icons';

export default function Profile(props) {
	const dispatch = useDispatch();
	const [profile, setprofile] = useState<any | null>(null);
	const [popupType, setpopupType] = useState('');
	const [selectedIndex, setselectedIndex] = useState(null);
	let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	useEffect(() => {
		fetchProfileDetails();
	}, []);

	const fetchProfileDetails = async () => {
		try {
			dispatch(setLoading(true));
			let response = await getProfileDetails({ userid: "" });
			setprofile(response);
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const deleteSkill = async (data) => {
		try {
			dispatch(setLoading(true));
			let response = await deleteSkillSet(data.id);
			showToaster(toasterTypes.SUCCESS, response.message);
			fetchProfileDetails();
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const deleteWork = async (data) => {
		try {
			dispatch(setLoading(true));
			let response = await deleteWorkExperience(data.id);
			showToaster(toasterTypes.SUCCESS, response.message);
			fetchProfileDetails();
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const deleteEducationData = async (data) => {
		try {
			dispatch(setLoading(true));
			let response = await deleteEducation(data.id);
			showToaster(toasterTypes.SUCCESS, response.message);
			fetchProfileDetails();
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const deleteAwardData = async (data) => {
		try {
			dispatch(setLoading(true));
			let response = await deleteAward(data.id);
			showToaster(toasterTypes.SUCCESS, response.message);
			fetchProfileDetails();
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const deleteResearchProfileData = async (data) => {
		try {
			dispatch(setLoading(true));
			let response = await deleteResearchProfile(data.id);
			showToaster(toasterTypes.SUCCESS, response.message);
			fetchProfileDetails();
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const deleteResearchExperienceData = async (data) => {
		try {
			dispatch(setLoading(true));
			let response = await deleteResearchExperience(data);
			showToaster(toasterTypes.SUCCESS, response.message);
			fetchProfileDetails();
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}
	/**
	 * delete research work
	 */
	const deleteResearchWorkata = async (data) => {
		try {
			dispatch(setLoading(true));
			let response = await deleteResearchWork(data.id);
			showToaster(toasterTypes.SUCCESS, response.message);
			fetchProfileDetails();
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	/**
	 * 
	 * @param type 
	 */

	const closePopup = (type) => {
		if (type === 'callAPI') {
			fetchProfileDetails();
		}
		setselectedIndex(null);
		setpopupType('');
	}

	return (
		<div className='profile-screen'>
			{popupType === 'profile' ?
				<ProfileDetails {...props} data={profile} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
				: popupType === 'contact' ?
					<ContactDetails {...props} data={profile.contact_details} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
					: popupType === 'address' ?
						<AddressDetails {...props} data={profile.address_details} selectedIndex={selectedIndex} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
						: popupType === 'skillSet' ?
							<SkillSet {...props} data={profile} selectedIndex={selectedIndex} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
							: popupType === 'work' ?
								<WorkExperience {...props} data={profile.work_details} selectedIndex={selectedIndex} userNumber={profile.usernumber} onAdd={() => { setselectedIndex(null); setpopupType('skillSet'); }} onClose={(type) => closePopup(type)} />
								: popupType === 'education' ?
									<EducationDetails {...props} data={profile.education_details} selectedIndex={selectedIndex} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
									: popupType === 'awards' ?
										<Awards {...props} data={profile.award_details} selectedIndex={selectedIndex} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
										: popupType === 'research' ?
											<ResearchProfile {...props} selectedIndex={selectedIndex} data={profile.research_profiles} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
											: popupType === 'publication' ?
												<PublicationDetails {...props} selectedIndex={0} data={profile} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
												: popupType === 'researchwork' ?
													<ResearchWork {...props} selectedIndex={selectedIndex} data={profile} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
													: null

			}
			{/* {
				window.innerWidth > 800 ? 

				<Row className='m-0'>
				<Col className='p-0'>
					<div className='profile-header-text'>User Profile</div>
				</Col>
				<Col className='p-0 text-right'>
					<i className='icon icon-notification'></i>
				</Col>
			</Row> : 
			<div></div>
			} */}

			{profile &&
				<>
					<div className='profile-header'>
						{(profile.photo_link || profile.first_name || profile.profile_title) &&
							<div className='edit-container'>
								<i className='icon-edit' onClick={() => setpopupType('profile')}></i>
								<i className='icon icon-notification'></i>
							</div>
						}
						<Row className='m-0'>
							<Col className=' p-0'>
								<div className='d-flex m-0 align-items-center'>
									{profile.photo_link ?
										<img className='profile-image' alt='Profile' src={profile.photo_link} /> :
										<div className='profile-container'>
											<img className='profile-icon' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
										</div>
									}
									<div className='name-container'>
										{!profile.profile_title && <div className='greet-text'>Hello</div>}
										<div className='first-text'>{profile.profile_title}  {profile.first_name}<span className='last-text'>{profile.last_name}</span></div>
										{/* <div className='title-text'>{profile.profile_title}</div> */}
									</div>
								</div>
							</Col>
							{!(profile.photo_link || profile.last_name || profile.profile_title) &&
								<Col className='p-0 d-flex justify-content-end align-items-center'>
									<i className='icon-add' onClick={() => setpopupType('profile')}></i>
									<span className='add-text' onClick={() => setpopupType('profile')}>ADD PROFILE</span>
								</Col>
							}
						</Row>
					</div>
					<div className='page-scroll'>
						<Row>
							<Col md={5}>
								<div className='card-container'>
									<Row className='m-0 mb-3'>
										<Col className='p-0'>
											<div className='card-header-text'>Contact Details</div>
										</Col>
										<Col className='p-0'>
											{Object.keys(profile?.contact_details).length === 0 ?
												<div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('contact')}>
													<i className='icon-add c-pointer'></i>
													<div className='add-text c-pointer'>ADD CONTACT</div>
												</div> :
												<div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('contact')}>
													<i className='icon-edit c-pointer'></i>
												</div>
											}
										</Col>
									</Row>
									{Object.keys(profile?.contact_details).length !== 0 &&
										<div className='contact-details'>
											<div>
												<i className='icon-email'></i>
												<span className='profile-data'>{profile?.contact_details?.email}</span>
											</div>
											<div>
												<i className='icon-phone'></i>
												<span className='profile-data'>{profile?.contact_details?.phone && profile?.contact_details?.phone.replace(" ","-")}</span>
											</div>
											{profile?.contact_details?.work_email &&
												<div className='mt-2'>
													<i className='icon-email'></i>
													<span className='profile-data'>{profile?.contact_details?.work_email}</span>
												</div>
											}
											{profile?.contact_details?.work_phone && profile?.contact_details?.work_phone.replace(" ","").length > 0 ? 
												<div>
													<i className='icon-phone'></i>
													<span className='profile-data'>{profile?.contact_details?.work_phone && profile?.contact_details?.work_phone.replace(" "," ")}</span>
												</div>
												:""
											}
										</div>
									}
								</div>
							</Col>
							<Col md={7}>
								<div className='card-container'>
									<Row className='m-0 mb-3'>
										<Col className='p-0'>
											<div className='card-header-text'>Address</div>
										</Col>
										<Col className='p-0'>
											{
												profile && profile?.address_details?.length == 0 || !profile?.address_details?

													<div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('address')}>
														<i className='icon-add c-pointer'></i>
														<div className='add-text c-pointer'>ADD ADDRESS</div>
													</div>
													: ""
											}

										</Col>
									</Row>
									{profile?.address_details?.map((obj, index) => (
										<Row key={index}>
											<Col md={10} className='mt-2'>
												{/* <div className='address-type'>{profile?.address_details?.length === 1 ? obj.address_type : obj.address_type}</div> */}
												<div className='address-text'>
													<span className='pin-text'>{obj.pincode} </span>
													{obj.address_text}, {obj.city}, {obj.state}, {obj.country}
												</div>
											</Col>
											<Col md={2} className='text-right'>
												<i className='icon-edit c-pointer' onClick={() => { setselectedIndex(index); setpopupType('address'); }}></i>
											</Col>
										</Row>
									))}
								</div>
							</Col>
						</Row>
						<div className='card-container'>
							<Row className='m-0 mb-3'>
								<Col className='p-0'>
									<div className='card-header-text'>Skill Set & Certifications</div>
								</Col>
								<Col className='p-0'>
									<div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('skillSet')}>
										<i className='icon-add c-pointer'></i>
										<div className='add-text c-pointer'>ADD SKILL</div>
									</div>
								</Col>
							</Row>
							{profile && profile?.skills?.map((obj, index) => (
								<Row key={index} className='m-0 mt-2 skill-container'>
									<Col md={4} className='p-0'>
										<div className='skill-data'>
											<div className='logo-border'>
												<img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
											</div>
											<div>
												<div className='skill-name'>{obj.skill_name}</div>
												<div className='skill-rating'>Skill rating <span className='skill-value'>{obj.self_rating}</span><span className='total-rating'> / 5</span></div>
											</div>
										</div>
									</Col>
									<Col md={6} className='p-0'>
										<div className='m-0 align-item skills'>
											{obj.certifications && (
												obj?.certifications.map((subObj, subIndex) => (
													<div className='p-0 skillset-cert' key={subIndex}>
														<div className='pl-3'>
															<div className='certification-name'>{subObj.certification_name}</div>
															{subObj.certification_url ? <div className="link" onClick={() => window.open(subObj.certification_url)}><LinkOutlined /><div className='link-text-cert'>{subObj.certification_url.replace(" ", "")}</div></div> : ""}
															<div className='certification-institute'><SnippetsOutlined />{subObj.organisation}</div>
															<div className='certification-date'>Issued : {subObj.issued_month ? subObj.issued_month.match(/[a-zA-Z]/g) ? subObj.issued_month + " " + subObj.issued_year : moment(subObj.issued_month + "/" + subObj.issued_year, "DD MM yyyy").format('DD-MMM-YYYY') : ""}</div>
														</div>
													</div>
												))
											)}
											{(!obj.certifications || obj.certifications.length === 0) &&
												<div className='no-text text-center w-100 pt-3'>No Certifications<span className='add-text' onClick={() => { setpopupType('skillSet'); setselectedIndex(index) }}>Add Certificate</span></div>
											}
										</div>
									</Col>
									<Col md={2} className='text-center'>
										<i className='icon-edit c-pointer mr-3' onClick={() => { setpopupType('skillSet'); setselectedIndex(index) }}></i>
										<i className='icon-delete c-pointer' onClick={() => deleteSkill(obj)}></i>
									</Col>
								</Row>
							))}
						</div>
						<div className='card-container'>
							<Row className='m-0 mb-3'>
								<Col className='p-0'>
									<div className='card-header-text'>Work Experience</div>
								</Col>
								<Col className='p-0'>
									<div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('work')}>
										<i className='icon-add c-pointer'></i>
										<div className='add-text c-pointer'>ADD WORK</div>
									</div>
								</Col>
							</Row>
							{profile && profile?.work_details?.map((obj, index) => (
								<Row key={index} className='m-0 mt-2 skill-container'>
									<Col md={4} className='p-0'>
										<div className='skill-data'>
											<div className='logo-border'>
												<img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
											</div>
											<div>
												<div className='skill-name'>{obj.company_name}, <span className='job-title'>{obj.job_title}</span></div>
												{/* <div className='total-rating'>{obj.job_description}</div> */}
												<div className='skill-rating'>{moment(obj.start_month + ' ' + obj.start_year).format('DD MMM yyyy') != "Invalid date" ? obj?.start_month && obj.start_month.match(/[a-zA-Z]/g) ? obj.start_month + obj.start_year : moment(obj.start_month + '/' + obj.start_year, "DD/MM/yyyy").format('DD MMM yyyy') : "" || moment(obj.start_month + '/' + obj.start_year, "DD/MM/YYYY").format('DD MMM yyyy')} {" to "} 
												{obj.end_month === 'till_date' ? 'Till Date' : obj.end_month.match(/[a-zA-Z]/g) ? obj.end_month + obj.end_year : moment(obj.end_month + '/' + obj.end_year, "DD/MM/YYYY").format('DD MMM yyyy')}</div>
												<div className='skill-rating'>{obj?.location?.city}, {obj?.location?.state}, {obj?.location?.country}</div>
											</div>
										</div>
									</Col>
									<Col md={6} className='p-0'>
										<div className='skill-data flex-column align-items-start'>
											<div className='skill-rating'>Skills / Description / Activites / Links</div>
											<div className='row m-0'>
												{obj.skills?.map((subObj, subIndex) => (
													<div className='activity-container' key={subIndex}>
														<div className='activity-text'>{subObj.skill_name}</div>
													</div>
												))}
											</div>
											<div className='total-rating'><a href={obj.documents} target='_blank'>{obj.documents}</a></div>
										</div>
									</Col>
									<Col md={2} className='text-center'>
										<i className='icon-edit c-pointer mr-3' onClick={() => { setpopupType('work'); setselectedIndex(index) }}></i>
										<i className='icon-delete c-pointer' onClick={() => deleteWork(obj)}></i>
									</Col>
								</Row>
							))}
						</div>
						<div className='card-container'>
							<Row className='m-0 mb-3'>
								<Col className='p-0'>
									<div className='card-header-text'>Education</div>
								</Col>
								<Col className='p-0'>
									<div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('education')}>
										<i className='icon-add c-pointer'></i>
										<div className='add-text c-pointer'>ADD EDUCATION</div>
									</div>
								</Col>
							</Row>
							{profile?.education_details?.map((obj, index) => (
								<Row key={index} className='m-0 mt-2 skill-container'>
									<Col md={4} className='p-0'>
										<div className='skill-data'>
											<div className='logo-border'>
												<img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
											</div>
											<div>
												<div className='skill-name'>{obj.institute}</div>
												<div className='total-rating'>{obj.degree} in {obj.field_of_study}</div>
												<div className='total-rating'>{obj.description}</div>
												<div className='skill-rating'>{moment(obj.start_month + ' ' + obj.start_year).format('DD MMM yyyy') != "Invalid date" ? obj.start_month && obj.start_month.match(/[a-zA-Z]/g) ? obj.start_month + ' ' + obj.start_year : moment(obj.start_month + '/' + obj.start_year, "DD/MM/yyyy").format('DD MMM yyyy') : "" || obj.start_month && obj.start_month.match(/[a-zA-Z]/g) ? obj.start_month + ' ' + obj.start_year : moment(obj.start_month + '/' + obj.start_year, "DD/MM/YYYY").format('DD MMM yyyy')}  
												{" to "}{obj.end_month === 'till_date' ? 'Till Date' : obj.end_month && obj.end_month.match(/[a-zA-Z]/g) ? obj.end_month + ' ' + obj.end_year : moment(obj.end_month + '/' + obj.end_year, "DD/MM/YYYY").format('DD MMM yyyy')}</div>
												<div className='skill-rating'>{obj?.location?.city}, {obj?.location?.state}, {obj?.location?.country}</div>
											</div>
										</div>
									</Col>
									<Col md={6} className='p-0'>
										<div className='skill-data flex-column align-items-start'>
											<div className='skill-rating'>Skills / Description / Activites / Links</div>
											<div className='row m-0'>
												{obj.activities.map((subObj, subIndex) => (
													<div className='activity-container' key={subIndex}>
														<div className='activity-text'>{subObj}</div>
													</div>
												))}
											</div>
											<div className='total-rating'>{obj.documents}</div>
										</div>
									</Col>
									<Col md={2} className='text-center'>
										<i className='icon-edit c-pointer mr-3' onClick={() => { setpopupType('education'); setselectedIndex(index) }}></i>
										<i className='icon-delete c-pointer' onClick={() => deleteEducationData(obj)}></i>
									</Col>
								</Row>
							))}
						</div>
						<Row>
							<Col md={12}>
								<div className='card-container'>
									<Row className='m-0 mb-3'>
										<Col className='p-0'>
											<div className='card-header-text'>Awards</div>
										</Col>
										<Col className='p-0'>
											<div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('awards')}>
												<i className='icon-add c-pointer'></i>
												<div className='add-text c-pointer'>ADD</div>
											</div>
										</Col>
									</Row>
									{profile?.award_details?.map((obj, index) => (
										<Row key={index} className='m-0 mt-2 skill-container'>
											<Col md={10} className='p-0'>
												<div className='skill-data'>
													<div className='logo-border'>
														<img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
													</div>
													<div>
														<div className='skill-name'>{obj.award_name}</div>
														{obj.award_url && <div className='link-text' onClick={() => window.open(obj.award_url)}>{obj.award_url}</div>}
														<div className='total-rating'>{obj.award_description}</div>
													</div>
												</div>
											</Col>
											<Col md={2} className='text-center'>
												<i className='icon-edit c-pointer mr-3' onClick={() => { setpopupType('awards'); setselectedIndex(index) }}></i>
												<i className='icon-delete c-pointer' onClick={() => deleteAwardData(obj)}></i>
											</Col>
										</Row>
									))}
								</div>
							</Col>

						</Row>
						<div className='card-container'>
							<Row className='m-0'>
								<Col className='p-0'>
									<div className='card-header-text'>Research Profiles</div>
								</Col>
								<Col className='p-0'>
									{Object.keys(profile.research_experience).length ? "" :
										<div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('publication')}>
											<i className='icon-add c-pointer'></i>
											<div className='add-text c-pointer'>ADD</div>
										</div>
									}
								</Col>
							</Row>


							<Row key={0} className='m-0 mt-2 skill-container'>
								<Col md={10} className='p-0'>
									<div className='skill-data'>
										{/* <span className='publication_content'>Orcid ID:  {profile.research_experience.orcid}</span> */}
										<span className='publication_content'>H Index:  {profile.research_experience.h_index}</span>
										<span className='publication_content'>I 10 Index:  {profile.research_experience.i10_index}</span>
										<span className='publication_content'>Patents:  {profile.research_experience.patent_count}</span>
										<span className='publication_content'>Publications: {profile.research_experience.publication_count}</span>
										<span className='publication_content'>Projects:  {profile.research_experience.projects_count}</span>
									</div>
								</Col>
								<Col md={2} className='text-center'>
									<i className='icon-edit c-pointer mr-3' onClick={() => { setpopupType('publication') }}></i>
									{/* <i className='icon-delete c-pointer' onClick={() => deleteResearchExperienceData(profile.research_experience.id)}></i> */}
								</Col>
							</Row>

							<Row key={0} className='m-0 mt-2 skill-container'>
								<Col md={12}>
									<div>
										<Row className='m-0 mb-3'>
											<Col className='p-0'>
												<div className='card-header-text'>Profile Links</div>
											</Col>
											<Col className='p-0'>
												<div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('research')}>
													<i className='icon-add c-pointer'></i>
													<div className='add-text c-pointer'>ADD</div>
												</div>
											</Col>
										</Row>
										{profile?.research_profiles?.map((obj, index) => (
											<Row key={index} className='m-0 mt-2 skill-container'>
												<Col md={10} className='p-0'>
													<div className='skill-data'>
														<div className='logo-border'>
															<img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
														</div>
														<div>
															<div className='skill-name'>{obj.profile_type}</div>
															{obj.profile_link && <div className='link-text' onClick={() => window.open(obj.profile_link)}>{obj.profile_link}</div>}
														</div>
													</div>
												</Col>
												<Col md={2} className='text-center'>
													<i className='icon-edit c-pointer mr-3' onClick={() => { setpopupType('research'); setselectedIndex(index) }}></i>
													<i className='icon-delete c-pointer' onClick={() => deleteResearchProfileData(obj)}></i>
												</Col>
											</Row>
										))}
									</div>
								</Col>
							</Row>
						</div>

						<div className='card-container'>
							<Row className='m-0'>
								<Col className='p-0'>
									<div className='card-header-text'>Research Works</div>
								</Col>
								<Col className='p-0'>
									<div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('researchwork')}>
										<i className='icon-add c-pointer'></i>
										<div className='add-text c-pointer'>ADD</div>
									</div>
								</Col>
							</Row>

							{profile?.research_project?.map((obj, index) => (
								<Row key={0} className='m-0 mt-2 research-work-container'>
									<Col md={10} className='p-0'>
										<div className='skill-data'>
											<div className='logo-border'>
												<img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
											</div>
											<div>

												<div className='project-name'>
													<span>{obj.project_name}</span>
													<span style={{ color: "#2F2E40", fontSize: "14px" }}> - </span>
													<span className="pub-type">{obj.publication_type?.label}</span>
												</div>

												{obj.authors && <div className='project-descp'><span style={{ color: "#ccc", fontSize: "14px" }}>Authors:</span> {obj.authors.map((item, index) => {
													return <span>{item}{index + 1 != obj.authors.length ? ", " : ""} </span>
												})}

												
												<div className="other-detail">
													{obj.journal ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>Journal Name:</span> {obj.journal} <span style={{ color: "#ccc", fontSize: "14px" }}> | </span></span> : ""}
													{obj.publisher_name ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>Published By: </span>{obj.publisher_name} <span style={{ color: "#ccc", fontSize: "14px" }}> | </span> </span> : ""}
													{obj?.published_date ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>Year: </span>{dayjs(obj?.published_date, "YYYY-MM-DD HH:mm:ss").format("YYYY")}<span style={{ color: "#ccc", fontSize: "14px" }}> | </span></span> : ""}
													{obj.volume ? <span> <span style={{ color: "#ccc", fontSize: "14px" }}>Volume: </span>{obj.volume} <span style={{ color: "#ccc", fontSize: "14px" }}> | </span></span> : ""}
													{obj.issue ? <span> <span style={{ color: "#ccc", fontSize: "14px" }}>Issue: </span>{obj.issue} <span style={{ color: "#ccc", fontSize: "14px" }}> | </span></span> : ""}
													{obj.page_no ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>PageNo.: </span>{obj.page_no}<span style={{ color: "#ccc", fontSize: "14px" }}> | </span> </span> : ""}
													{obj.place ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>Place.: </span>{obj.place} </span> : ""}
												</div>
												{obj.doi ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>DOI: </span><a target="_blank" href={obj.doi}>{obj.doi}</a> </span> : ""}
												</div>
												}
												{/* <div className='publish-date'>{obj?.published_date && expectedDateFormat(obj?.published_date, 'DD MMM YYYY', 'YYYY-MM-DD')}</div> */}
												{obj.project_status.label == "Published" ?
													<div><span className='project-status-completed'>{obj.project_status.label}</span>{obj.url ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>| URL: </span><a target="_blank" href={obj.url}>{obj.url}</a></span> : ""}</div>
													:
													<div className='project-status-progress'>{obj.project_status.label}</div>
												}

											</div>
										</div>
									</Col>
									<Col md={2} className='text-center'>
										<i className='icon-edit c-pointer mr-3' onClick={() => { setpopupType('researchwork'); setselectedIndex(index) }}></i>
										<i className='icon-delete c-pointer' onClick={() => deleteResearchWorkata(obj)}></i>
									</Col>
								</Row>
							))
							}

						</div>
					</div>
				</>
			}
		</div>
	)
}
