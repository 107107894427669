import { Menu } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { setLoading } from '../../store/slices/auth';
import { showToaster, toasterTypes } from '../../widgets';
import Tabs from '../sampleSize/Tabs';
import PeopleInvite from './PeopleInvite';
import PeopleInviteMobile from './PeopleInviteMobile';
// import { deleteAward, deleteEducation, deleteResearchProfile, deleteSkillSet, deleteWorkExperience, getProfileDetails } from './apiController';

export default function People(props) {
	const dispatch = useDispatch();
	const [profile, setprofile] = useState<any | null>(null);
	const [popupType, setpopupType] = useState('');
	const [selectedIndex, setselectedIndex] = useState(null);
	const [projectData, setprojectData] = useState<any | null>(null);
	const location = useLocation();

	const tabData = [
		{
		  title: "Project Details",
		  id: 1,
		},
		{
		  title: "Study Design",
		  id: 2,
		},
		{
		  title: "Data Collection",
		  id: 3,
		},
		{
		  title: "Data Analysis",
		  id: 4,
		},
		{
		  title: "People",
		  id: 5,
		},
		{
		  title: "Registered Events",
		  id: 6,
		},
	  ];


	useEffect(() => {
		let tempState: any = location.state;
        setprojectData(tempState);
	}, []);



	return (
		<>
		{window.innerWidth > 700 ?
		<div className='people-screen'>
			
			<>
			<div className='people-data'>
						<div className='people-text'>				
							<span></span>
						</div>				
						<div className='people-notification'>
							<i className='icon icon-notification'></i>
						</div>
				</div>
				<Tabs data={tabData} selectedTab={4} projectData={projectData} />
			</>
			
			<PeopleInvite/>
			

		</div>
		:
		<div className='people-screen-mob'>		
		   <PeopleInviteMobile data={location.state}/>
   		</div>
		}


		</>
	)
}
