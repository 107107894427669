import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { setLoading } from '../../store/slices/auth';
import { CustomButton } from '../../widgets';
import ModalPopup from '../../widgets/modal';
import Tabs from '../sampleSize/Tabs';
import { loadHtml } from './apiController';
import { calcCCS_P, calcDCSS1_P, calcDCSS_P, calcICSCBA_N, calcICSCBA_P, calcRCTNRCT_N, calcRCTNRCT_P, calcSampleSizeForExpectedSensitivity, calcICSCBA_CC } from './formula';
import { ArrowLeftOutlined } from '@ant-design/icons';

export default function StudyModule() {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [projectData, setprojectData] = useState<any | null>(null);
	const [htmlData, sethtmlData] = useState('');
	const [isPopup, setisPopup] = useState(false);
	const [formulaResponse, SetformulaResponse] = useState<any>([]);
	const [sampleProduct, SetSampleProduct] = useState<any>(location.state);
	const [permissionParams, SetPermissionParams] = useState<any>({});
	const tabData = [
		{
			title: "Project Details",
			id: 1,
			tab: "project details"
		},
		{
			title: "Study Design",
			id: 2,
			tab: "study design"
		},
		{
			title: "Data Collection",
			id: 3,
			tab: "Data collection"
		},
		{
			title: "Data Analysis",
			id: 4,
			tab: "Data analysis"
		},
		{
			title: "Mentors",
			id: 5,
			tab: "mentors"
		},
		{
			title: "Registered Events",
			id: 6,
			tab: "register events"
		},
	];

	/**
	 * 
	 * @param data 
	 */
	const getPermissions = async (data) => {
		await data.permissions && data.permissions.map(items => {
			console.log(Object.keys(items)[0].replace(/([^\w]+|\s+)/g, ''))

			if (Object.keys(items)[0].replace(/([^\w]+|\s+)/g, '') == "studydesign") {
				items[Object.keys(items)[0]].map(item => {
					if (item.permissions.filter(itm => itm.title == "allowed" && itm.is_selected == true).length != 0) {
						if (item.action == "previous button") {
							SetPermissionParams(prevState => ({ ...prevState, previous: "allowed" }))
						} else if (item.action == "cancel button") {
							SetPermissionParams(prevState => ({ ...prevState, cancel: "allowed" }))
						} else if (item.action == "save & continue button") {
							SetPermissionParams(prevState => ({ ...prevState, save: "allowed" }))
						}
					} else {
						if (item.action == "previous") {
							SetPermissionParams(prevState => ({ ...prevState, previous: "forbidden" }))
						} else if (item.action == "save & continue button") {
							SetPermissionParams(prevState => ({ ...prevState, save: "forbidden" }))
						} else if (item.action == "cancel") {
							SetPermissionParams(prevState => ({ ...prevState, cancel: "forbidden" }))
						}
					}

				})
			}

		})
	}

	useEffect(() => {
		console.log(location.state)
		if (location.state) {
			let stateData: any = location.state;
			getPermissions(stateData)
			setprojectData(stateData);
			fetchData(stateData);
			if (!stateData.SDMCode) {
				navigate('/sampleSize', { state: stateData })
			}
		} else {
			navigate('/project');
		}
	}, [location.state, navigate]);


	const fetchData = async (stateData) => {
		try {
			dispatch(setLoading(true));
			let response = await loadHtml(stateData.Output);
			populatePlaceHolders(response, stateData);
			dispatch(setLoading(false));

		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const escapeRegExp = (inputString: string) => {
		return inputString.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
	}

	const populatePlaceHolders = async (studyData: any, stateData) => {
		let direction = "";
		let inputs: any = stateData.Input;
		inputs.forEach((data) => {
			let placeHolder = data.PlaceHolder;
			if (placeHolder === "${Direction}") {
				direction = data.Value;
			}
		});
		let additionValues: any = [];
		inputs.forEach((data) => {
			let inputData = {
				PlaceHolder: "",
				Value: "",
			};
			let placeHolder = data.PlaceHolder;
			let value = data.Value;
			if (placeHolder === "${V}") {
				inputData.PlaceHolder = "${V_Label}";
				data.DropDown.forEach((optionObj) => {
					if (optionObj['value'] == data.Value) {
						inputData.Value = optionObj['name'];
					}
				});
				additionValues.push(inputData);
			}
			if (placeHolder === "${U}") {
				inputData.PlaceHolder = "${U_Label}";
				data.DropDown.forEach((optionObj) => {
					if (optionObj['value'] === data.Value) {
						inputData.Value = optionObj['name'];
					}
				});
				additionValues.push(inputData);
			}
			if (placeHolder === "${Z}") {
				inputData.PlaceHolder = "${Z_Label}";
				data.DropDown.forEach((optionObj) => {
					if (optionObj['value'] === data.Value) {
						inputData.Value = optionObj['name'];
					}
				});
				additionValues.push(inputData);
			}
			if (direction !== "" && placeHolder === "${V}") {
				let spValue = value?.split(":");
				if (direction === "one sided") {
					data.Value = spValue[0];
				} else if (direction === "two sided") {
					data.Value = spValue[1];
				}
			}
			if (placeHolder === "${PropotionOutcomIntervention1}") {
				let value = parseFloat(data.Value);
				value = value / 100;
				inputData.PlaceHolder = "${PropotionOutcomIntervention1_Per}";
				inputData.Value = value + "";
				additionValues.push(inputData);
			} else if (placeHolder === "${PropotionOutcomIntervention2}") {
				let value = parseFloat(data.Value);
				value = value / 100;
				inputData.PlaceHolder = "${PropotionOutcomIntervention2_Per}";
				inputData.Value = value + "";
				additionValues.push(inputData);
			}
			studyData = studyData.replace(new RegExp(escapeRegExp(placeHolder), 'g'), data.Value);
		});

		stateData.QuestionHistory.forEach((data) => {
			let inputData = {
				PlaceHolder: "",
				Value: "",
			};
			if (data.QuestionId != undefined) {
				inputData.PlaceHolder = "${" + data.QuestionId + "}";
			} else {
				inputData.PlaceHolder = "${" + data.questionid + "}";
			}
			if (data.Answer != undefined) {
				inputData.Value = data.Answer;
			} else {
				inputData.Value = data.answer;
			}
			additionValues.push(inputData);
		});
		inputs.forEach((data) => {
			let inputData = {
				PlaceHolder: "",
				Value: "",
			};
			inputData.PlaceHolder = data.PlaceHolder;
			inputData.Value = data.Value;
			additionValues.push(inputData);
		});
		additionValues.forEach((data) => {
			studyData = studyData.replace(new RegExp(escapeRegExp(data.PlaceHolder), 'g'), data.Value);
		});

		let calArray: any = [];
		console.log(inputs)
		console.log(stateData.SDMSubCode)
		switch (stateData.SDMSubCode) {
			case "DAS":
				calArray = calcSampleSizeForExpectedSensitivity(inputs);
				break;
			case "RCTNRCT_N":
				calArray = calcRCTNRCT_N(inputs);
				break;
			case "RCTNRCT_P":
				calArray = calcRCTNRCT_P(inputs);
				break;
			case "ICSCBA_N":
				calArray = calcICSCBA_N(inputs);
				break;
			case "ICSCBA_P":
				calArray = calcICSCBA_P(inputs);
				break;
			case "DCSS_P":
				calArray = calcDCSS_P(inputs);
				break;
			case "DCSS1_P":
				calArray = calcDCSS1_P(inputs);
				break;
			case "CCS_N":
				calArray = calcRCTNRCT_N(inputs);
				break;
			case "CCS_P":
				calArray = calcCCS_P(inputs);
				break;
			case "ICSCBA_CC":
				calArray = calcICSCBA_CC(inputs);
				break;
			case "DCSS_CC":
				calArray = calcICSCBA_CC(inputs);
				break;
			default:
				break;
		}

		calArray = await calArray;
		console.log(calArray)
		calArray.forEach((data: any) => {
			studyData = studyData.replace(new RegExp(escapeRegExp(data.PlaceHolder), 'g'), data.Value)
		});
		studyData = studyData.replace(new RegExp(escapeRegExp("${SDMName}"), 'g'), stateData.SDMName)
		studyData = studyData.replace(new RegExp(escapeRegExp("${SDMSubname}"), 'g'), stateData.SDMSubname)
		sethtmlData(studyData);
		dispatch(setLoading(false));
	}

	const download = () => {
		var divContents: any = document.getElementById("printContent")?.innerHTML;
		var a: any = window.open('', '', 'height=1000, width=1000');
		a.document.write(`<title>${projectData.SDMName}</title>`);
		a.document.write(divContents);
		a.document.close();
		a.print();
	}

	return (
		<div className='study-module-page'>
			{isPopup &&
				<ModalPopup
					modalType='delete'
					modalText="Are you sure you want to cancel?"
					actionText="This will take you to the beginning of sample size calculation."
					closeModal={() => setisPopup(false)}
					onAction={() => navigate('/sampleSize', { state: projectData })}
				/>
			}
		  <Row className="page-header m-0">
            <Col className="p-0" md={12}>
              <ArrowLeftOutlined style={{color:"#fff",fontSize:"24px",margin:"0px 10px"}} onClick={() => navigate("/project")}/>
              <span className="project-title">
               {projectData?.TopicDesc}
              </span>
            </Col>
          </Row>
			<Tabs data={tabData} selectedTab={1} projectData={projectData} />
			<div className='tab-page' id="printContent" dangerouslySetInnerHTML={{ __html: htmlData }}></div>
			<Row className='footer-container m-0'>
				<Col className='p-0'>
					{permissionParams.cancel == "allowed" || Object.keys(permissionParams).length == 0 ?
						<div className='cancel-text' onClick={() => setisPopup(true)}>Cancel</div>
						: ""
					}
				</Col>

				<Col className='p-0'>
					<div className='d-flex m-0 justify-content-end'>
						<div className='mr-3'>
							{permissionParams.previous == "allowed" || Object.keys(permissionParams).length == 0 ?
								<CustomButton type="alert-primary" onClick={() => navigate('/sampleSize', { state: { data: sampleProduct, typedata: "STD" } })} className="w-100" text="Previous"></CustomButton>
								: ""
							}
						</div>
					</div>
				</Col>
				<Col className='p-0'>
					<div className='d-flex m-0 justify-content-end'>
						<div className='mr-3'>
							<CustomButton type="alert-primary" onClick={() => download()} className="w-100" text="Download"></CustomButton>
						</div>
						<CustomButton type="primary" onClick={() => localStorage.getItem("device") == "desktop" ? navigate('/project') : navigate('/projectmobile')} className="w-100" text="Save and Continue"></CustomButton>
					</div>
				</Col>
			</Row>
		</div>
	)
}
