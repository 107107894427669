import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { expectedDateFormat } from "../../utils/Utils";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import PeopleInvite from "../people/PeopleInvite";
import Tabs from '../sampleSize/Tabs';
import TabPermission from "./TabPermission";
import { AffiliationFormElements } from "../createProject/FormElements";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { FieldItem } from "../../widgets/fields";
import { updateProject } from "../createProject/apiController";
import { getRoles } from "../dataCollection/apiController";

export default function ProjectDetail(props){
	const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(AffiliationFormElements, {}, {onValueChange}); 
	const [projectData, setprojectData] = useState<any>([]);
	const [roleList, SetRoleList] = useState<any>([]);
	const navigate = useNavigate();
    const location = useLocation();
	const tabData = [
		{
		  title: "Project Details",
		  id: 1,
		  tab:"project details"
		},
		{
		  title: "Study Design",
		  id: 2,
		  tab:"study design"
		},
		{
		  title: "Data Collection",
		  id: 3,
		  tab:"Data collection"
	
		},
		{
		  title: "Data Analysis",
		  id: 4,
		  tab:"Data analysis"
		},
		{
		  title: "Mentors",
		  id: 5,
		  tab:"mentors"
	
		},
		{
		  title: "Registered Events",
		  id: 6,
		  tab:"register events"
	
		},
	  ];

	
    useEffect(() => {
		if (location.state) {
			let stateData: any = location.state;
			setprojectData(stateData);
			updateValue({strictmode:stateData.strict_mode})
		} else {
			navigate('/project');
		}
	}, [location.state, navigate]);

	
	/**
	 * get list of roles 
	 * update role state by API response 
	 * replacing the keys with required name id and title
	 */
	useEffect(() =>{
		try {
            const usertypes = getRoles();    
            usertypes.then(data => {
                SetRoleList(data.data.map(({
                    key: id,
                    name: title
                }) => ({
                    id,
                    title
                })));
            })

        } catch (e: any) {
            throw e
        }
	},[])


	  /**
	   * edit project 
	   * @param obj 
	   */
	  const onProjectEdit = (obj) => {
		navigate('/createProject', {state: obj});
	  }

	  /**
	   * on subscribe
	   */
	  const onSubscribe = () =>{
		navigate('/subscriptions');
	  }

	  /**
	   * on strict mode swtich action
	   */
	  const strictModeAction = async(strictvalue) => {
		let topic: any = {
			"TopicDesc": projectData?.TopicDesc,
			"TopicBigDesc": projectData?.TopicBigDesc,
			"SpecCodes": projectData?.SpecCode,
			"UserNumber": projectData?.UserNumber,
			"affiliation_info": projectData?.affiliation_info,
			"funding_info": projectData?.funding_info,
			"key_words": projectData?.key_words,
			"strict_mode":strictvalue
		}
		topic.TopicCode = projectData?.TopicCode || projectData?.TopicID;
		try{
			let response = await updateProject(topic, projectData?.TopicCode || projectData?.TopicID);
			if(response){
				showToaster(toasterTypes.SUCCESS,strictvalue ? "Strict Mode Enabled" : "Strict Mode Disabled")
			}
		}catch(e:any){
			throw e;
		}
        //await updateProject(topic, props.projectData.TopicCode || props.projectData.TopicID);
	  }

	  /**
	   * onvaluechange form field
	   */
	  function onValueChange(name, value, values, fieldValues = null){
		let resetValue = {}
		if(name == "strictmode"){
			strictModeAction(value)
		}
		return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]

	  }

    return(
        <>
         <div className="project-detail-container">
            <Row className='page-header m-0'>
				<Col className='p-0 project-title' md={12}>
				<ArrowLeftOutlined style={{color:"#fff",fontSize:"24px",margin:"0px 10px"}} onClick={() => navigate("/project")}/>

					{projectData?.TopicDesc}</Col>
				<Col className='p-0'></Col>
			</Row>
            <Tabs data={tabData} selectedTab={0} projectData={projectData} />

			<div className="detail-permission">
			<div className="details">
					<h6 className="title">Details</h6>
					<Card className="detail-card">
						<Row>
							<Col md={11}>
							    <div className="description">
									{projectData && projectData?.TopicDesc}
								</div>
							</Col>
							<Col md={1}>
								<EditOutlined onClick={()=>onProjectEdit(projectData)}/>
							</Col>
						</Row>
						<Row>
							<Col md={10}>
						<div className="sub-details">
							{/* <div className="project-data">
								<span className="label-content">Created By</span>
								<span className="content">
								{projectData && projectData?.CreatedBy}
								</span>
							</div> */}
							<div className="project-data">
								<span className="label-content">Created On</span>
								<span className="content">
								{expectedDateFormat(projectData?.CreatedOn, 'DD MMM YYYY', 'YYYY-MM-DD')}
								</span>
							</div>
							{/* <div className="project-data">
								<span className="label-content">Current Progress</span>
								<span className="content">{projectData && projectData?.TopicStatus || "-"}</span>
							</div> */}

							<div className="project-data">
								<span className="label-content">Subscription</span>
								<span className="content">Paid</span>
							</div>

							<div className="project-data">
								<span className="label-content">Team Size</span>
								<span className="content">1</span>
							</div>
						</div>
						</Col>						
						<Col md={2}>
							<CustomButton 
								type="primary"
								text="Subscribe"
								onClick={()=>onSubscribe()}
							/>
						</Col>
						</Row>
					</Card>
			</div>

			<div className="obj-permission">
			<Row>
				<Col md={5} >
				    <h6 className="title">Objectives</h6>
					<Card className="objectives">
						{
							projectData?.TopicBigDesc && projectData?.TopicBigDesc.map((item,i) => {
								return <><Row>
								<Col md={10}>
									<div className="objectives-content">
									{item}
									</div>
								</Col>
								<Col md={2}>
									<EditOutlined onClick={()=>onProjectEdit(projectData)}/>
								</Col>
							</Row>
							<hr></hr></>
							})
							
						}
						
				
					</Card>

					<h6 className="title-strict">Strict Mode</h6>
					<Card className="objectives-strict">
					   <Row>
							<Col md={10}>
								<div className="strict-content">
								By enabling the strict mode, ensure the integrity, reliability, transparency and compliance for the project.
								</div>
							</Col>
							
						</Row>
						<Row>
							<Col>							   
							<FieldItem
								{...AffiliationFormElements.strictmode}
								value={values.strictmode}
								values={values.strictmode}
								onChange={(...e) =>handleChange(AffiliationFormElements.strictmode.name, ...e)}
								touched={fields.strictmode && fields.strictmode.hasError}
								error={fields.strictmode && fields.strictmode.errorMsg}
                       		 />
							</Col>
						</Row>
						
					</Card>
				</Col>
				<Col md={7}>
					<h6 className="title">Permissions</h6>
					<Card className="permission-card">

						<TabPermission projectdata={projectData} data={roleList && roleList} selectedTab={0}/>
					</Card>
				</Col>
			</Row>
			</div>
			</div>
         </div>
        </>
    )
}