import React from 'react'
import { Col, Row } from 'reactstrap'
import { FieldItem, FIELD_TYPES } from '../../widgets/fields'

export default function ViewForm(props) {
    function getValues(data) {
        console.log("Test"+data);
        let valuesData: any = [];
        data.forEach(element => {
            valuesData.push({
                label: element,
                value: element
            });
        });
        return valuesData;
    }

    return (
        <div className='view-form'>
            <Row className='m-0'>
                <Col className='p-0'>
                    <div className='form-name'>{props.formData?.form_name}</div>
                </Col>
                <Col className='p-0 text-right'>
                    <i className='icon icon-close' onClick={() => props.onClose()} />
                </Col>
            </Row>
            <div className='form-scroll'>
                {Object.keys(props.variables || {}).map((obj, index) => (
                    <div key={index}>
                        <div className='category-container'>
                            <div className='category-text'>{obj !== 'empty' ? obj : ''}</div>
                        </div>
                        <Row>
                            {props.variables[obj].map((subObj, subIndex) => (
                                !subObj.is_time_series && <Col md={4} key={subIndex}>                                  

                                    <FieldItem
                                        name={subObj.id}
                                        placeholder={(subObj.field_type === 'number' || subObj.field_type === 'textBox' || subObj.field_type === 'textArea') ? 'Enter' : 'Select'}
                                        label={subObj.field_name}
                                        type={subObj.field_type === 'fileUpload' ? FIELD_TYPES.TEXT_AREA : subObj.field_type === 'dateField' ? FIELD_TYPES.DATE_PICKER : subObj.field_type === 'dropDown' ? FIELD_TYPES.DROP_DOWN : subObj.field_type === 'boolean' ? FIELD_TYPES.BOOLEAN : subObj.field_type === 'radioButton' ? FIELD_TYPES.RADIO_BUTTON : subObj.field_type === 'checkBox' ? FIELD_TYPES.CHECK_BOX : subObj.field_type === 'textArea' ? FIELD_TYPES.TEXT_AREA : FIELD_TYPES.TEXT}
                                        value={props.isPreview ? '' : subObj.selectedValue}
                                        values={getValues(subObj.field_value || [])}
                                        isListed={true}
                                        isDisabled={true}
                                    />
                                

                                </Col>
                            ))}
                            {props.variables[obj].map((subObj, subIndex) => (
                                subObj.is_time_series && <Col md={12} key={subIndex}>
                                    <FieldItem
                                        name={subObj.id}
                                        placeholder={(subObj.field_type === 'number' || subObj.field_type === 'number' || subObj.field_type === 'textArea') ? 'Enter' : 'Select'}
                                        label={subObj.field_name}
                                        labelIcon="icon-loop"
                                        type={subObj.field_type === 'dateField' ? FIELD_TYPES.DATE_PICKER : subObj.field_type === 'dropDown' ? FIELD_TYPES.DROP_DOWN : subObj.field_type === 'boolean' ? FIELD_TYPES.BOOLEAN : subObj.field_type === 'radioButton' ? FIELD_TYPES.RADIO_BUTTON : subObj.field_type === 'checkBox' ? FIELD_TYPES.CHECK_BOX : subObj.field_type === 'textArea' ? FIELD_TYPES.TEXT_AREA : FIELD_TYPES.TEXT}
                                        value={props.isPreview ? '' : subObj.selectedValue}
                                        values={getValues(subObj.field_value || [])}
                                        isListed={true}
                                        isDisabled={true}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                ))}
            </div>
        </div>
    )
}
