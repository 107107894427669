import React, { useEffect, useRef, useState } from 'react';
import { useGlobalFilter, useTable } from 'react-table';
import { Button, Col, Input, InputGroup, Row } from 'reactstrap';
import { CustomButton } from '../button';

function Table(props: any) {
    const { searchHelpText = "Search", columns, data, isSearch, isFilter, isCreate, isAdd, isGroup } = props;
    const {
        header,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        allColumns,
        setGlobalFilter,
        state
    } = useTable({ columns, data }, useGlobalFilter);
    const { globalFilter } = state;
    const [showFilter, setshowFilter] = useState(false);
    const [showSearch, setshowSearch] = useState(false);

    let formContainerRef = useRef(null);
    useOutsideAlerter(formContainerRef);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setshowFilter(false)
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    // Render the UI for your table
    return (
        <div style={{ maxWidth: '100%' }} className="custom-table-container">
            <Row className='options-container align-items-center'>
                <Col className='table-title row m-0 align-items-center'>
                    <span className='mr-3'>{props.header || 'All Entities'} | <b>{data.length}</b></span>
                    {isCreate &&
                        <CustomButton type="alert-primary" onClick={() => props.onAction(4)} className="w-100" text="Create"></CustomButton>
                    }
                </Col>
                <Col className='text-right'>
                    <div className='d-flex m-0 justify-content-end'>
                        {isAdd &&
                            <CustomButton type="alert-primary" onClick={() => props.onAction(5)} className="w-50" text="Add"></CustomButton>
                        }
                         {isGroup &&
                            <>
                            <CustomButton type="alert-primary" onClick={() => props.onAction(6)} className="group-btn" style={{margin:"0px 5px"}} text="Group Values"></CustomButton>
                            <CustomButton type="primary" onClick={() => props.onAction(7)} className="data-btn" style={{margin:"0px 5px"}} text="Analyse Data"></CustomButton>
                            </>
                        }
                        {isSearch &&
                            <div className='search-container'>
                                {showSearch ?
                                    <InputGroup>
                                        <Input type="search" className="data-Table-common_search"
                                            placeholder={searchHelpText} bsSize="sm"
                                            onChange={(e) => setGlobalFilter(e.target.value)}
                                            value={globalFilter}
                                        />
                                        <Button addonType="append">
                                            <i className="icon icon-close search-button" onClick={() => { setGlobalFilter(""); setshowSearch(false) }}></i>
                                        </Button>
                                    </InputGroup> :
                                    <Button className='table-header-button' size="sm" onClick={() => setshowSearch(true)}>
                                        <i className="icon icon-search"></i>
                                    </Button>
                                }
                            </div>
                        }
                        {isFilter &&
                            <Button className='table-header-button' size="sm" onClick={() => setshowFilter(true)}>
                                <i className="icon icon-filter"></i>
                            </Button>
                        }
                    </div>
                </Col>
                {showFilter &&
                    <div>
                        <div ref={formContainerRef} className="filter-body">
                            {allColumns.map(column => (
                                <div key={column.id}>
                                    <label>
                                        <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                                        {column.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </Row>
            <div style={{overflow: 'auto', position: 'relative'}}>
                <table {...getTableProps()} className="custom-table">
                    <thead className='table-header'>
                        {headerGroups.map((headerGroup: any) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any) => (
                                    <th style={{ minWidth: column.width + 'px' }} className={column?.className || ''} {...column.getHeaderProps()}>{column.render('label')}</th>
                                ))}
                                {(props.isView || props.isEdit || props.isDelete) && <th className='text-center sticky-col-header'>Actions</th>}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="table-body">
                        {rows.map((row: any, i: any) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell: any) => {
                                        return <td style={{ minWidth: cell.column.width + 'px' }} {...cell.getCellProps()} className={cell.column?.className || ''}>{cell.render('Cell')}</td>
                                    })}
                                    {(props.isView || props.isEdit || props.isDelete) &&
                                        <td className='text-center sticky-col'>
                                            {props.isView && <i className="icon icon-eye" onClick={() => props.onAction(1, row, i)}></i>}
                                            {props.isEdit && <i className="icon icon-edit" onClick={() => props.onAction(2, row, i)}></i>}
                                            {props.isDelete && <i className="icon icon-delete" onClick={() => props.onAction(3, row, i)}></i>}
                                        </td>
                                    }
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function CustomTable(props: any) {

    const columns = React.useMemo(
        () => props.tableProps,
        [props.tableProps]
    )

    return (
        <Table {...props} columns={columns} data={props.data}  pagination={true}/>
    )
}

export default CustomTable
