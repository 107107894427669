import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { setLoading } from '../../store/slices/auth';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';
import ModalComponent from '../common/Modal/modal';
import { getProjectTeamList } from '../people/apiController';
import { createForm, updateForm, getRoles, getUsers } from './apiController';
import { CreateFormElements } from './FormElements';

export default function CreateForm(props) {
	const dispatch = useDispatch();
	const initialValues = useMemo(() => (
		props.previousState ?
			props.previousState :
			{ name: '', description: '', type: null, group: null, surveyType: {label:"No",value:false} }
	), [props.previousState]);
	const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(CreateFormElements, initialValues, { onValueChange });
	const [permissions, setpermissions] = useState([{ role: null, users: [], user: '', accessType: [] }]);
	const [roles, setroles] = useState<any | []>([]);

	useEffect(() => {
		console.log(props.previousState)
		fetchRoles(0);
		if (props.isEdit) {
			let tempType = CreateFormElements.type.values.filter((obj) => obj.value === props.formData?.form_type);
			let tempSurveyType = props.formData?.survey_form ? CreateFormElements.surveyType.values[0] : CreateFormElements.surveyType.values[1];
			updateValue({ group: { label: props.group.label, value: props.group.value }, name: props.formData?.form_name, description: props.formData?.form_desc, type: tempType[0], surveyType: tempSurveyType});
		}else {
			updateValue({ group: { label: props.group.label, value: props.group.value } })
		}
		console.log(props)
	}, []);

	const fetchRoles = async (index) => {
		try {
			dispatch(setLoading(true));
			let tempRoles = await getRoles();
			if(tempRoles && tempRoles.data && tempRoles.data.length > 0) {
				tempRoles.data.forEach((obj, index) => {
					obj.label = obj.name;
					obj.value = obj.name.toLowerCase();
					obj.index = index;
					obj.users = [];
				});
				if(props.isEdit) {
					let temp: any = [];
					props.formData?.form_role_access?.forEach((roleObj) => {
						let tempPermissions = tempRoles.data.filter((obj) => obj.value === roleObj.role_name);
						temp.push({ role: tempPermissions[0], users: [], user: '', accessType: roleObj.permissions });
					});
					if(props.formData?.form_user_access && props.formData?.form_user_access.length > 0) {
						console.log(props.formData?.form_user_access)
						props.formData?.form_user_access?.forEach(async(userObj, userIndex) => {
							let tempPermissions = tempRoles.data.filter((obj) => obj.value === userObj.role_name);
							userObj.label = userObj.email;
							userObj.value = userObj.user_number;
							let roleIndex = tempRoles?.data.findIndex((obj) => obj.value === userObj.role_name);
							if(roleIndex >= 0) {
								try {
									dispatch(setLoading(true));
									let projectid: any = { projectid: props.projectData.id };
									//let tempUsers = await getUsers(props.projectData.TopicCode || props.projectData.TopicID, roleData[roleIndex].label.toLowerCase());
									let tempUsers = await getProjectTeamList(projectid, 1, 100, "", "accepted",userObj.role_name);
									
									//let tempUsers = await getUsers(props.projectData.TopicCode || props.projectData.TopicID, userObj.role_name);
									if(tempUsers?.data && tempUsers.data.length > 0) {
										tempUsers.data.forEach((obj) => {
											obj.label = obj.emailid;
											obj.value = obj.usernumber;
										});
										tempRoles.data[roleIndex].users = tempUsers.data;
										userObj.permissions.filter(item => item.is_selected == true).map(permissionItem => {
											let accessTypeValue = permissionItem.title == "read" ? "r" : permissionItem.title == "write" ? "w" : "f";
										temp.push({
											role: tempPermissions[0],
											users: tempUsers.data,
											user: userObj, accessType: accessTypeValue
										});
									})
									} else {
										userObj.permissions.filter(item => item.is_selected == true).map(permissionItem => {
											let accessTypeValue = permissionItem.title == "read" ? "r" : permissionItem.title == "write" ? "w" : "f";
										
										temp.push({
											role: tempPermissions[0],
											users: [],
											user: userObj, accessType: accessTypeValue
										});
									})
									}
									dispatch(setLoading(false));
								} catch (e: any) {
									userObj.permissions.filter(item => item.is_selected == true).map(permissionItem => {
										let accessTypeValue = permissionItem.title == "read" ? "r" : permissionItem.title == "write" ? "w" : "f";
									
									temp.push({
										role: tempPermissions[0],
										users: [],
										user: userObj, accessType: accessTypeValue
									});
								})
									dispatch(setLoading(false));
								}
							}
							if(userIndex + 1 === props.formData?.form_user_access.length) {
								if(temp.length === 0) {
									temp = [{ role: null, users: [], user: '', accessType: [] }];
								}
								setpermissions(temp);
								setroles(tempRoles.data);
							}
						});
					} else {
						if(temp.length === 0) {
							temp = [{ role: null, users: [], user: '', accessType: [] }];
						}
						setpermissions(temp);
						setroles(tempRoles.data);
					}
				} else {
					if(tempRoles?.data && tempRoles.data.length > 0) {
						let tempPermissions: any = cloneDeep(permissions);
						tempPermissions[index] = { role: null, users: [], user: '', accessType: [] };
						fetchUsers(tempRoles.data, 0, tempPermissions, index);
					}
				}
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			setroles([]);
			dispatch(setLoading(false));
		}

		console.log(permissions)
	}

	const fetchUsers = async (roleData, roleIndex, tempPermissions, perIndex) => {
		if(roleData[roleIndex].users.length === 0) {
			try {
				dispatch(setLoading(true));
				console.log(props.projectData.id);
				let projectid: any = { projectid: props.projectData.id };
				//let tempUsers = await getUsers(props.projectData.TopicCode || props.projectData.TopicID, roleData[roleIndex].label.toLowerCase());
				let tempUsers = await getProjectTeamList(projectid, 1, 100, "", "accepted",roleData[roleIndex].label.toLowerCase());
				if(tempUsers?.users && tempUsers.users.length > 0) {
					tempUsers.users.forEach((obj) => {
						obj.label = obj.invitee_name;
						obj.value = obj.invitee_id;
					});
					roleData[roleIndex].users = tempUsers.users;
					tempPermissions[perIndex].users = tempUsers.users;
				}
				if(tempPermissions.length === 0) {
					tempPermissions = [{ role: null, users: [], user: '', accessType: [] }];
				}
				setpermissions(tempPermissions);
				setroles(roleData);
				dispatch(setLoading(false));
			} catch (e: any) {
				setroles(roleData);
				if(tempPermissions.length === 0) {
					tempPermissions = [{ role: null, users: [], user: '', accessType: [] }];
				}
				setpermissions(tempPermissions);
				dispatch(setLoading(false));
			}
		} else {
			tempPermissions[perIndex].users = roleData[roleIndex].users;
			setroles(roleData);
			if(tempPermissions.length === 0) {
				tempPermissions = [{ role: null, users: [], user: '', accessType: [] }];
			}
			setpermissions(tempPermissions);
		}
	}

	function onValueChange(name, value, values, fieldValues = null) {
		let resetValue = {};
		console.log(values)
		return [{
			...values,
			...resetValue,
			[name]: value,
		}]
	}
	
	function changePermissions(name, value, index) {
		let temp: any = cloneDeep(permissions);
		console.log(value)
		console.log(name)
		if(name === 'role') {
			temp[index].user = null;
			temp[index].users = [];
			temp[index][name] = value;
			fetchUsers(roles, value.index, temp, index); //commented time being
		} else if(name === 'user') {
			let isUser = false;
			temp.forEach((obj) => {
				console.log(obj.user)

				if (obj.user && obj.user.invitee_id == value.invitee_id || obj.user?.user_number == value.invitee_id) {
					isUser = true;
					return;
				}
			});


			if(isUser) {
				showToaster(toasterTypes.ERROR, 'User is already added');
			} else {
				temp[index][name] = value;
			 }
			setpermissions(temp);
		} else {
			temp[index][name] = value;
			setpermissions(temp);
		}
	}

	const onSave = async () => {
		console.log(values)
		if (validateValues(["name", "description", "type", "group", "surveyType"])) {
			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
		} else {
			let form_role_access: any = [];
			let form_user_access: any = [];

			permissions.forEach((obj: any) => {
				if (obj.user && obj.user.value && obj.user.label && (obj.user.invitee_role || obj.user.role_name) && obj.accessType && obj.accessType.length > 0) {
					form_user_access.push({
						"user_id": obj.user.invitee_id || obj.user.user_number,
						"user_number": obj.user.invitee_id || obj.user.user_number,
						"email": obj.user.label,
        				"role_name": obj.user.invitee_role || obj.user.role_name,
						"permissions": [
							{
								"title": "read",
								"permission": "read",
								"is_selected": obj.accessType == "r" ? true : false
							},
							{
								"title": "write",
								"permission": "write",
								"is_selected": obj.accessType == "w" ? true : false
							},
							{
								"title": "forbidden",
								"permission": "forbidden",
								"is_selected": obj.accessType == "f" ? true : false
							}
						]
					});
				} else if(obj.role?.value && obj.accessType && obj.accessType.length > 0) {
					form_role_access.push({
						"role_name": obj.role.value,
						"permissions": [
							{
								"title": "read",
								"permission": "read",
								"is_selected": obj.accessType == "r" ? true : false
							},
							{
								"title": "write",
								"permission": "write",
								"is_selected": obj.accessType == "w" ? true : false
							},
							{
								"title": "forbidden",
								"permission": "forbidden",
								"is_selected": obj.accessType == "f" ? true : false
							}
						]
					});
				}
			});
			let reqObj: any = {
				"topicid": props.projectData?.TopicID || props.projectData?.TopicCode,
				"form_name": values.name,
				"form_desc": values.description,
				"form_type": values.type.value,
				"form_group_id": values.group.value,
				"form_group": values.group.label,
				"survey_form": values.surveyType.value,
				"strict_mode":props.projectData?.strict_mode,
				form_role_access,
				form_user_access
			}
			if (props.isEdit) {
				reqObj.id = props.formData?.id
			}
			try {
				dispatch(setLoading(true));
				let response: any;
				console.log(reqObj)
				if (props.isEdit) {
					response = await updateForm(reqObj);
				} else {
					response = await createForm(reqObj);
				}
				dispatch(setLoading(false));
				showToaster(toasterTypes.SUCCESS, response.message);
				props.onFormCreation();
			} catch (e: any) {
				dispatch(setLoading(false));
			}
		}
	}

	const addUser = () => {
		let temp: any = cloneDeep(permissions);
		temp.push({ role: null, users: [], user: '', accessType: [] });
		setpermissions(temp);
	}

	return (
		<ModalComponent
			width={window.innerWidth > 700 ? "1200px" : "350px"}
			isOpen={true}
			onClose={() => props.onClose()}
			header={props.isEdit ? "Edit Form" : "Create Form"}
			centered={true}
			customClass='profile-modal form-modal'
			body={
				<div>
					<Row>
						<Col md={8}>
							<FieldItem
								{...CreateFormElements.name}
								value={values.name}
								onChange={(...e) => handleChange(CreateFormElements.name.name, ...e)}
								touched={fields.name && fields.name.hasError}
								error={fields.name && fields.name.errorMsg}
								// isDisabled={props.isEdit}
							/>
						</Col>

					</Row>
					<Row>
						<Col md={12}>
							<FieldItem
								{...CreateFormElements.description}
								value={values.description}
								onChange={(...e) => handleChange(CreateFormElements.description.name, ...e)}
								touched={fields.description && fields.description.hasError}
								error={fields.description && fields.description.errorMsg}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={4} className='custom-size'>
							<FieldItem
								{...CreateFormElements.type}
								value={values.type}
								onChange={(...e) => handleChange(CreateFormElements.type.name, ...e)}
								touched={fields.type && fields.type.hasError}
								error={fields.type && fields.type.errorMsg}
							/>
						</Col>
						<Col md={4}>
							<FieldItem
								{...CreateFormElements.group}
								value={values.group}
								values={props.groups}
								onChange={(...e) => handleChange(CreateFormElements.group.name, ...e)}
								touched={fields.group && fields.group.hasError}
								error={fields.group && fields.group.errorMsg}
							/>
						</Col>
						<Col md={4}>
							<FieldItem
								{...CreateFormElements.surveyType}
								value={values.surveyType}
								onChange={(...e) => handleChange(CreateFormElements.surveyType.name, ...e)}
								touched={fields.surveyType && fields.surveyType.hasError}
								error={fields.surveyType && fields.surveyType.errorMsg}
							/>
						</Col>
						{/* <Col md={2}>
							<FieldItem
								{...CreateFormElements.strictmode}
								value={values.strict_mode}
								onChange={(...e) => handleChange(CreateFormElements.strictmode.name, ...e)}
								touched={fields.strictmode && fields.strictmode.hasError}
								error={fields.strictmode && fields.strictmode.errorMsg}
							/>
						</Col> */}
					</Row>
					<div className='permissions-border'>
						<Row className='m-0'>
							<Col className='p-0'>
								<div className='permissions-text'>Permissions</div>
							</Col>
							<Col className='p-0'>
								<div className='user-text' onClick={() => addUser()}>Add User</div>
							</Col>
						</Row>
					</div>

					{roles.length > 0 && (
						permissions.map((obj: any, index) => (
							
							<Row className='m-0 permissions-row' key={index}>
								<Col md="3">
									<FieldItem
										{...CreateFormElements.role}
										value={obj.role}
										values={roles}
										onChange={(...e) => changePermissions(CreateFormElements.role.name, ...e, index)}
									/>
								</Col>
								<Col md="4">
									<FieldItem
										{...CreateFormElements.user}
										value={obj.user}
										values={obj.users}
										onChange={(...e) => changePermissions(CreateFormElements.user.name, ...e, index)}
									/>
								</Col>
								<Col md="5">
									<FieldItem
										{...CreateFormElements.accessType}
										value={obj.accessType}
										onChange={(...e) => changePermissions(CreateFormElements.accessType.name, ...e, index)}
									/>
								</Col>
							</Row>
						)
					))}
				</div>
			}
			footer={
				<div>
					<CustomButton type="primary" isDisabled={false} text="Save" onClick={() => onSave()}></CustomButton>
				</div>
			}
		/>
	)
}
