import { truncate } from "lodash";
import { FIELD_TYPES } from "../../widgets/fields";

export const ProfileFormElements = {
	profilePhoto: {
		name: "profilePhoto",
		placeholder: "Drag and Drop or Browse",
		label: "",
		type: FIELD_TYPES.FILE_UPLOAD,
		isMandatory: true
	},
	profileTitle: {
		name: "profileTitle",
		placeholder: "Enter",
		label: "Profile Title",
		type: FIELD_TYPES.DROP_DOWN,
		values: [{ label: "Mr.", value: "Mr." }, { label: "Mrs.", value: "Mrs." }, { label: "Miss", value: "Miss" }, { label: "Ms.", value: "Ms." }, { label: "Dr.", value: "Dr." }],
		isMandatory: true
	},
	firstName: {
		name: "firstName",
		placeholder: "Enter",
		label: "First Name",
		isMandatory: true
	},
	lastName: {
		name: "lastName",
		placeholder: "Enter",
		label: "Last Name",
		isMandatory: true
	}
};

export const ContactFormElements = {
	email: {
		name: "email",
		placeholder: "Enter",
		label: "Email",
		isMandatory: true,
		regex: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
		messages: {
			regex: `Please enter valid email`
		}
	},
	phonecode: {
		name: "phonecode",
		placeholder: "Code",
		label: "Code",
		isMandatory: true,
		type: FIELD_TYPES.DROP_DOWN,
		messages: {
			regex: `Please enter valid code`
		}
	},
	phone: {
		name: "phone",
		placeholder: "Enter",
		label: "Phone",
		isMandatory: true,
		type: FIELD_TYPES.NUMBER,
		regex:/^[0-9]{10,14}$/,
		messages: {
			regex: `Please enter valid phone number`
		}
	},
	workEmail: {
		name: "workEmail",
		placeholder: "Enter",
		label: "Work Email",
		regex: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
		messages: {
			regex: `Please enter valid email`
		}
	},
	workphonecode: {
		name: "workphonecode",
		placeholder: "Code",
		label: "Code",
		isMandatory: false,
		type: FIELD_TYPES.DROP_DOWN,
		messages: {
			regex: `Please enter valid code`
		}
	},
	workPhone: {
		name: "workPhone",
		placeholder: "Enter",
		label: "Work Phone",
		type: FIELD_TYPES.NUMBER,
		regex:/^[0-9]{10,14}$/,
		messages: {
			regex: `Please enter valid phone number`
		}
	}
};

export const AddressFormElements = {
	addressType: {
		name: "addressType",
		placeholder: "Select",
		label: "Address Type",
		type: FIELD_TYPES.DROP_DOWN,
		isMandatory: false,
		values: [{ label: 'Permanent', value: 'permanent' }, { label: 'Present', value: 'present' }]
	},
	pinCode: {
		name: "pinCode",
		placeholder: "Enter",
		label: "Pin Code",
		isMandatory: true,
		regex: /^[0-9]*$/,
		messages: {
			regex: `Please enter valid pin code`
		}
	},
	country: {
		name: "country",
		placeholder: "Select",
		label: "Country",
		type: FIELD_TYPES.DROP_DOWN,
		isMandatory: true
	},
	state: {
		name: "state",
		placeholder: "Select",
		label: "State",
		type: FIELD_TYPES.DROP_DOWN,
		isMandatory: true
	},
	city: {
		name: "city",
		placeholder: "Select",
		label: "City",
		isMandatory: true
	},
	address: {
		name: "address",
		placeholder: "Enter",
		label: "Address",
		type: FIELD_TYPES.TEXT_AREA,
		isMandatory: true
	},
	same: {
		name: "same",
		placeholder: "",
		label: "",
		type: FIELD_TYPES.CHECK_BOX,
		values: [{ label: 'Use Same for Permanent', value: true }]
	}
};

export const SkillSetFormElements = {
	skillName: {
		name: "skillName",
		placeholder: "Enter",
		label: "Skill Name",
		isMandatory: true
	},
	rating: {
		name: "rating",
		placeholder: "Enter",
		label: "Rating Of 5",
		isMandatory: true,
		type:FIELD_TYPES.NUMBER,
		regex: /^[1-5]{1}$/,
		messages: {
			regex: `Please enter valid rating `
		}
	},
	certificationName: {
		name: "certificationName",
		placeholder: "Enter",
		label: "Certification Name",
		isMandatory: true
	},
	score: {
		name: "score",
		placeholder: "Enter",
		label: "Score",
		isMandatory: true
	},
	issuingOrganisation: {
		name: "issuingOrganisation",
		placeholder: "Enter",
		label: "Issuing Organisation",
		isMandatory: true
	},
	certificateUrl: {
		name: "certificateUrl",
		placeholder: "Enter",
		label: "Certificate URL",
		isMandatory: false,
		regex: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
		messages: {
			regex: `Please enter valid URL (Example: https://couguide.in)`
		}
	},
	certificateId: {
		name: "certificateId",
		placeholder: "Enter",
		label: "Certificate ID",
		isMandatory: false
	},
	issuedDate: {
		name: "issuedDate",
		placeholder: "Select",
		label: "Issued Date",
		type: FIELD_TYPES.DATEPICKER_NEW,
		isMandatory: true
	},
	validUpto: {
		name: "validUpto",
		placeholder: "Select",
		label: "Valid Upto",
		type: FIELD_TYPES.DATEPICKER_NEW,
		isMandatory: false
	},
	certificateUpload: {
		name: "certificateUpload",
		placeholder: "Upload",
		label: "Upload Certificate",
		type: FIELD_TYPES.RESEARCHWORK_UPLOAD,
		isMandatory: false
	},
};

export const WorkFormElements = {
	jobTitle: {
		name: "jobTitle",
		placeholder: "Enter",
		label: "Job Title",
		isMandatory: true
	},
	jobDescription: {
		name: "jobDescription",
		placeholder: "Enter",
		label: "Job Description",
		type: FIELD_TYPES.TEXT_AREA,
		isMandatory: false
	},
	industry: {
		name: "industry",
		placeholder: "Select",
		label: "Industry",
		type: FIELD_TYPES.TEXT_AUTOCOMPLETE,
		isMandatory: true
	},
	employeeType: {
		name: "employeeType",
		placeholder: "Select",
		label: "Employee Type",
		type: FIELD_TYPES.DROP_DOWN,
		isMandatory: true
	},
	companyName: {
		name: "companyName",
		placeholder: "Organization Name",
		label: "Organization Name",
		isMandatory: true,
		type:FIELD_TYPES.TEXT_AUTOCOMPLETE,
		isMandatory:true
	},
	currentProfession: {
		name: "currentProfession",
		placeholder: "Select",
		label: "Current Profession",
		type: FIELD_TYPES.DROP_DOWN,
		values: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
		isMandatory: true
	},
	startingFrom: {
		name: "startingFrom",
		placeholder: "Select",
		label: "Starting From",
		type: FIELD_TYPES.DATEPICKER_NEW,
		isMandatory: true
	},
	workedTill: {
		name: "workedTill",
		placeholder: "Select",
		label: "Worked Till",
		type: FIELD_TYPES.DATEPICKER_NEW,
		isMandatory: false
	},
	country: {
		name: "country",
		placeholder: "Select",
		label: "Country",
		type: FIELD_TYPES.DROP_DOWN,
		isMandatory: true
	},
	state: {
		name: "state",
		placeholder: "Select",
		label: "State",
		type: FIELD_TYPES.DROP_DOWN,
		isMandatory: true
	},
	city: {
		name: "city",
		placeholder: "Select",
		label: "City",
		isMandatory: true
	},
	skillsUsed: {
		name: "skillsUsed",
		placeholder: "Enter",
		label: "Skills Used",
		type: FIELD_TYPES.MULTI_SELECT,
		isMandatory: true
	},
	info: {
		name: "info",
		placeholder: "Enter",
		label: "Activities / Links",
		type: FIELD_TYPES.TEXT_AREA,
		isMandatory: false
	}
};

export const EducationFormElements = {
	institute: {
		name: "institute",
		placeholder: "Enter",
		label: "Institute",
		isMandatory: true,
		type: FIELD_TYPES.TEXT_AUTOCOMPLETE
	},
	description: {
		name: "description",
		placeholder: "Enter",
		label: "Description",
		type: FIELD_TYPES.TEXT_AREA,
		isMandatory: false
	},
	degree: {
		name: "degree",
		values:[{label:"Undergraduate",value:"Undergraduate"},
		{label:"Postgraduate (Masters)",value:"PostgraduateMaster"},
		{label:"Postgraduate (Diploma)",value:"UndergraduateDiploma"},
		{label:"Research scholar (PhD)",value:"ResearchScholarPhD"},
		{label:"Others",value:"Others"}],
		placeholder: "Select",
		label: "Degree",
		type: FIELD_TYPES.TEXT_AUTOCOMPLETE,
		isMandatory: true
	},
	fieldOfStudy: {
		name: "fieldOfStudy",
		placeholder: "Select",
		label: "Field Of Study",
		type: FIELD_TYPES.TEXT_AUTOCOMPLETE,
		isMandatory: true
	},
	currentEducation: {
		name: "currentEducation",
		placeholder: "Select",
		label: "Education Status",
		type: FIELD_TYPES.DROP_DOWN,
		values: [{ label: 'Completed', value: 'completed' }, { label: 'Ongoing', value: 'ongoing' }],
		isMandatory: true
	},
	startingFrom: {
		name: "startingFrom",
		placeholder: "Select",
		label: "Starting From",
		type: FIELD_TYPES.DATEPICKER_NEW,
		isMandatory: true
	},
	to: {
		name: "to",
		placeholder: "Select",
		label: "To",
		type: FIELD_TYPES.DATEPICKER_NEW,
		isMandatory: false
	},
	activities: {
		name: "activities",
		placeholder: "Select",
		label: "Activities",
		type: FIELD_TYPES.MULTI_TEXT,
		isMandatory: false
	},
	country: {
		name: "country",
		placeholder: "Select",
		label: "Country",
		type: FIELD_TYPES.DROP_DOWN,
		isMandatory: true
	},
	state: {
		name: "state",
		placeholder: "Select",
		label: "State",
		type: FIELD_TYPES.DROP_DOWN,
		isMandatory: true
	},
	city: {
		name: "city",
		placeholder: "Select",
		label: "City",
		isMandatory: true
	}
};

export const AwardsFormElements = {
	awardType: {
		name: "awardType",
		placeholder: "Award Type",
		label: "Award Type",
		type: FIELD_TYPES.TEXT,
		isMandatory: true
	},
	awardName: {
		name: "awardName",
		placeholder: "Enter",
		label: "Award Name",
		isMandatory: true
	},
	awardAgency: {
		name: "awardAgency",
		placeholder: "Enter",
		label: "Award Agency",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	description: {
		name: "description",
		placeholder: "Enter",
		label: "Description",
		type: FIELD_TYPES.TEXT_AREA,
		isMandatory: false
	},
	url: {
		name: "url",
		placeholder: "Enter",
		label: "URL",
		isMandatory: false,
		regex: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
		messages: {
			regex: `Please enter valid URL (Example: https://couguide.in)`
		}
	}
};

export const ResearchFormElements = {
	url: {
		name: "url",
		placeholder: "Enter",
		label: "URL",
		isMandatory: false,
		regex: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
		messages: {
			regex: `Please enter valid URL (Example: https://couguide.in)`
		}
	},
	platform: {
		name: "platform",
		placeholder: "Select",
		label: "Platform",
		type: FIELD_TYPES.DROP_DOWN,
		isMandatory: false,
		values: [{ label: "Orcid", value: "orcid" },
		{ label: "Research Gate", value: "Research Gate" },
		{ label: "Researcher ID (Web of science)", value: "Researcher ID (Web of science)" },
		{ label: "Scopus ID", value: "Scopus ID" },
		{ label: "Google Scholar", value: "Google Scholar" }]
	},
	publicationtype: {
		name: "publicationtype",
		placeholder: "Publication Type",
		label: "Publication Type",
		type: FIELD_TYPES.DROP_DOWN,
		isMandatory: true,
		values: [{ label: 'Journal Article', value: 'Journal Article' },
		{ label: 'Book', value: 'Book' },
		{ label: 'Book Section', value: 'Book Section' },
		{ label: 'Blog Post', value: 'Blog Post' },
		{ label: 'Conference Paper', value: 'Conference Paper' },
		{ label: 'Funding Proposal', value: 'Funding Proposal' },
		{ label: 'Pre Print', value: 'Pre Print' },
		{ label: 'Poster', value: 'Poster' },
		{ label: 'Thesis', value: 'Thesis' },
		{ label: 'Code', value: 'Code' },
		{ label: 'Cover Page', value: 'Cover Page' },
		{ label: 'Data', value: 'Data' },
		{ label: 'Experiment Findings', value: 'Experiment Findings' },
		{ label: 'Method', value: 'Method' },
		{ label: 'Negative Result', value: 'Negative Result' },
		{ label: 'Patent', value: 'Patent' },
		{ label: 'Raw Data', value: 'Raw Data' },
		{ label: 'Technical Report', value: 'Technical Report' }
		]
	},
	title: {
		name: "title",
		placeholder: "Title",
		label: "Title",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	sectiontitle: {
		name: "sectiontitle",
		placeholder: "Section Title",
		label: "Section Title",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	journalname: {
		name: "journalname",
		placeholder: "Journal Name",
		label: "Journal Name",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	publishername: {
		name: "publishername",
		placeholder: "Publisher Name",
		label: "Publisher Name",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	publishedyear: {
		name: "publishedyear",
		placeholder: "Published Year",
		label: "Published Year",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	issue: {
		name: "issue",
		placeholder: "Issue",
		label: "Issue",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	projectDescription: {
		name: "projectDescription",
		placeholder: "Abstract/ Description",
		label: "Abstract/ Description",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	fundStatus: {
		name: "fundStatus",
		placeholder: "Funding Status",
		label: "Funding Status",
		type: FIELD_TYPES.DROP_DOWN,
		values: [{ label: 'Funded', value: 'funded' },
		{ label: 'Non Funded', value: 'nonfunded' }],
		isMandatory: false
	},
	unitBudget: {
		name: "unitBudget",
		placeholder: "Currency",
		label: "Currency",
		type: FIELD_TYPES.DROP_DOWN,
		values: [{ label: '₹', value: 'INR' },
		{ label: '$', value: 'Dollar' }],
		isMandatory: false
	},
	projectBudget: {
		name: "projectBudget",
		placeholder: "Budget",
		label: "Budget",
		type: FIELD_TYPES.NUMBER,
		isMandatory: false
	},
	fundingAgency: {
		name: "fundingAgency",
		placeholder: "Funding Agency",
		label: "Funding Agency",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	doi: {
		name: "doi",
		placeholder: "DOI",
		label: "DOI(optional)",
		type: FIELD_TYPES.TEXT,
		isMandatory: false,
		regex: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
		messages: {
			regex: `Please enter valid URL (Example: https://couguide.in)`
		}
	},
	citationCount: {
		name: "citationCount",
		placeholder: "Citation Count",
		label: "Citation Count",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	publishedDate: {
		name: "publishedDate",
		placeholder: "Year",
		label: "Year",
		type: FIELD_TYPES.DATEPICKER_NEW,
		isMandatory: false,
	},
	volume: {
		name: "volume",
		placeholder: "Volume",
		label: "Volume",
		type: FIELD_TYPES.TEXT,
		isMandatory: false,

	},
	pageNo: {
		name: "pageNo",
		placeholder: "Page No",
		label: "Page No",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	journal: {
		name: "journal",
		placeholder: "Published Journal",
		label: "Published Journal",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	projectStatus: {
		name: "projectStatus",
		placeholder: "Project Status",
		label: "Project Status",
		type: FIELD_TYPES.DROP_DOWN,
		values: [{ "label": "Published", value: "Published" },
		{ "label": "Accepted", value: "Accepted" },
		{ "label": "Under Review", value: "Under Review" },
		{ "label": "Under Preparation", value: "Under Preparation" }],
		isMandatory: false
	},
	rolePlayed: {
		name: "rolePlayed",
		placeholder: "Role Played",
		label: "Role Played",
		values: [{ "label": "Concepts", value: "Concepts" },
		{ "label": "Design", value: "Design" },
		{ "label": "Definition of intellectual content", value: "Definition of intellectual content" },
		{ "label": "Literature search", value: "Literature search" },
		{ "label": "Clinical studies", value: "Clinical studies" },
		{ "label": "Experimental studies", value: "Experimental studies" },
		{ "label": "Data acquisition", value: "Data acquisition" },
		{ "label": "Data analysis", value: "Data analysis" },
		{ "label": "Statistical analysis", value: "Statistical analysis" },
		{ "label": "Manuscript preparation", value: "Manuscript preparation" },
		{ "label": "Manuscript editing", value: "Manuscript editing" },
		{ "label": "Manuscript review", value: "Manuscript review" },
		{ "label": "Others", value: "Others" }],
		type: FIELD_TYPES.MULTI_SELECT,
		isMandatory: false
	},
	author: {
		name: "author",
		placeholder: "Author",
		label: "Author",
		type: FIELD_TYPES.MULTI_TEXT,
		isMandatory: false
	},
	documentsUpload: {
		name: "documentsUpload",
		placeholder: "Upload",
		label: "Upload Documents",
		type: FIELD_TYPES.RESEARCHWORK_UPLOAD,
		isMandatory: false
	},
	place: {
		name: "place",
		placeholder: "Place",
		label: "Place",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	country: {
		name: "country",
		placeholder: "Country",
		label: "Country",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	assignee: {
		name: "assignee",
		placeholder: "Assignee",
		label: "Assignee",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	issuingauthority: {
		name: "issuingauthority",
		placeholder: "Issuing authority",
		label: "Issuing authority",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	patentnumber: {
		name: "patentnumber",
		placeholder: "Patent number",
		label: "Patent number",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	fillingdate: {
		name: "fillingdate",
		placeholder: "Filling Date",
		label: "Filling Date",
		type: FIELD_TYPES.DATEPICKER_NEW,
		isMandatory: false
	},
	applicationnumber: {
		name: "applicationnumber",
		placeholder: "Application number",
		label: "Application number",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	issuedate: {
		name: "issuedate",
		placeholder: "Issue Date",
		label: "Issue Date",
		type: FIELD_TYPES.DATEPICKER_NEW,
		isMandatory: false
	},
	reference: {
		name: "reference",
		placeholder: "References",
		label: "References",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	legalstatus: {
		name: "legalstatus",
		placeholder: "Legal status",
		label: "Legal status",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	identifier: {
		name: "identifier",
		placeholder: "Identifier",
		label: "Identifier",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	repository: {
		name: "repository",
		placeholder: "Repository",
		label: "Repository",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	repositorylocation: {
		name: "repositorylocation",
		placeholder: "Repository location",
		label: "Repository location",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	version: {
		name: "version",
		placeholder: "Version",
		label: "Version",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
};

export const PublicationFormElements = {
	orcid: {
		name: "orcid",
		placeholder: "Enter",
		label: "Orcid ID",
		isMandatory: false
	},
	hIndex: {
		name: "hIndex",
		placeholder: "Enter",
		label: "H Index",
		isMandatory: false
	},
	iIndex: {
		name: "iIndex",
		placeholder: "Enter",
		label: "I 10 Index",
		type: FIELD_TYPES.TEXT,
		isMandatory: false
	},
	totalProjects: {
		name: "totalProjects",
		placeholder: "Enter",
		label: "Total Projects",
		type: FIELD_TYPES.NUMBER,
		isMandatory: false
	},
	totalPatents: {
		name: "totalPatents",
		placeholder: "Enter",
		label: "Total Patents",
		type: FIELD_TYPES.NUMBER,
		isMandatory: false
	},
	publicationCounts: {
		name: "publicationCounts",
		placeholder: "Publication Count",
		label: "Publication Count",
		type: FIELD_TYPES.NUMBER,
		isMandatory: false
	},
	areasOfInterest: {
		name: "areasOfInterest",
		placeholder: "Enter",
		label: "Areas Of Interest",
		type: FIELD_TYPES.TEXT_AREA,
		isMandatory: false
	}

};

