import { CloseOutlined, EditOutlined, FileAddFilled } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap"
import { FieldItem } from "../../widgets/fields"
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { GroupFormElements } from "./FieldElements"
import * as monaco from 'monaco-editor';

export default function AddVariableComponent(props) {
    const [values, fields, handleChange, { validateValues, reset, updateValue }] = useFieldItem(GroupFormElements, {}, { onValueChange });
    const [code, Setcode] = useState(false);
    const editorRef = useRef(null);
    const [item,SetItem] = useState(props.data);

    const [isEdit, SetEdit] = useState(props.data.key ==  1 ? true : false);
    useEffect(() => {  
     // if (editorRef.current) {
     // editorRef.current.dispose();
      //}

        editorRef.current = monaco.editor.create(document.getElementById('editor-container'+props.data.key), {
          value: '',
          language: 'coguideLanguage'+props.data.key, // Use the custom language ID
          theme: "vs-dark",
          renderLineHighlight: "none",
          lineNumbers: 'off',
          glyphMargin: false
        });   

       // if(props.suggest){
          loadLang()
        //}
       
        return () => {
          // Dispose of the suggestion provider
          editorRef.current.dispose();
          };
      }, []);


      const loadLang = () =>{
         //...(existing code)  
       //  if (!window.hasRegisteredCompletionProviderForYourLanguage) {
         const coguideLanguageId = 'coguideLanguage'+props.data.key; // Replace with your custom language ID
         //editorRef.current.dispose();
         monaco.languages.register({ id: coguideLanguageId });      
         monaco.languages.setMonarchTokensProvider(coguideLanguageId, {
           tokenizer: {
                   root:[
                   ]
           },
         });   
         
         let mon = monaco.languages.registerCompletionItemProvider(coguideLanguageId, {
          provideCompletionItems: (model, position) => {
            return {
              suggestions: [],
            };
          }
        })
         mon.dispose();

         
       monaco.languages.registerCompletionItemProvider(coguideLanguageId, {
           provideCompletionItems: (model, position) => {
             // Implement your custom suggestion logic here          
             let suggestionsdata = [
               {
                 label: 'opt +',
                 kind: monaco.languages.CompletionItemKind.Text,
                 insertText:"+"
               },
               {
                 label: 'opt -',
                 kind: monaco.languages.CompletionItemKind.Text,
                 insertText:"-"
               }, {
                 label: 'opt *',
                 kind: monaco.languages.CompletionItemKind.Text,
                 insertText:"*"
               }, {
                 label: 'opt /',
                 kind: monaco.languages.CompletionItemKind.Text,
                 insertText:"/"
               }, {
                 label: 'opt &&',
                 kind: monaco.languages.CompletionItemKind.Text,
                 insertText:"&&"
               }, {
                 label: 'opt ||',
                 kind: monaco.languages.CompletionItemKind.Text,
                 insertText:"||"
               }, {
                 label: 'opt ==',
                 kind: monaco.languages.CompletionItemKind.Text,
                 insertText:"=="
               }, {
                 label: 'opt !=',
                 kind: monaco.languages.CompletionItemKind.Text,
                 insertText:"!="
               }
             ];
     
             props.variable.filter(itemvalue => itemvalue.label == props.variablevalue).map((item) => {
               let Obj = {
                 label: 'var :'+item.label,
                 kind: monaco.languages.CompletionItemKind.Text,
                 insertText:item.label
               }       
               suggestionsdata.push(Obj)      
             })
 
             if(props.grouptype == "Compute"){
               props.forms?.map((item) => {
                 let Obj = {
                   label: 'form :'+item.label,
                   kind: monaco.languages.CompletionItemKind.Text,
                   insertText:item.label
                 }       
                 suggestionsdata.push(Obj)      
               })
             }         
             console.log(suggestionsdata) 
             //editorRef.current.updateOptions({ suggest: { suggestions: suggestionsdata } });
             return {
               suggestions: suggestionsdata,
             };
           },
          
         });

         monaco.editor.setModelLanguage(editorRef.current.getModel(), coguideLanguageId);
         //registerMonaco.dispose();
        //  window.hasRegisteredCompletionProviderForYourLanguage = true;
        // }
      }


      const onClear = () => {
       
        loadLang();
        SetEdit(false)

      }
    /**
    * on value change update form data
    * @param name 
    * @param value 
    * @param values 
    * @param fieldValues 
    * @returns 
    */
    function onValueChange(name, value, values, fieldValues = null) {
        let resetValue = {};
        //console.log(editorRef.current.getValues())
        console.log(document.getElementById('editor-container'+props.data.key).innerText)
       props.getData(name,value,document.getElementById('editor-container'+props.data.key).innerText)  
        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
      }

      

    return (
        <>
            
            <div className="add-variable">
                <Row>
                    <Col md={7}>
                        <Row>
                            <Col md={12}>
                            {/* <MonacoEditor
                                height="300"
                                language="javascript"
                                theme="vs-dark"
                                editorDidMount={handleEditorDidMount}
                              /> */}
                              <div id={"editor-container"+props.data.key}  style={{ width: '100%', height: '40px' }}/>
                            </Col>                             
                        </Row>
                    </Col>

                    <Col md={4}>
                        <div className="new-value">
                            <FieldItem
                               name={"newcode" + item.key}
                               value={values["newcode"+item.key]}
                               values={[]}
                               onChange={(...e) => handleChange("newcode"+item.key, ...e)}
                               touched={fields["newcode"+item.key] && fields["newcode"+item.key].hasError}
                               error={fields["newcode"+item.key] && fields["newcode"+item.key].errorMsg}
                            />
                        </div>
                    </Col>
                    <Col md={1}>
                        <CloseOutlined style={{ color: "red" }} onClick={() => props.remove(item.key)} />
                    </Col>
                </Row>
            </div>
        </>
    )
}