import { CloseCircleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalComponent from "../common/Modal/modal";
import { uploadDocument, uploadSelectedFile } from "../submissions/apiController";
import { addResearchWork, updateResearchWork } from "./apiController";
import { ResearchFormElements } from "./FormElements";
import dayjs from 'dayjs';

export default function ResearchWork(props) {
    const initialValues = React.useMemo(() => ({
        publicationtype: {},
        projectStatus: {},
        author: [],
        title: "",
        sectiontitle: "",
        journalname: "",
        publishername: "",
        publishedDate: null,
        volume: "",
        issue: "",
        pageNo: "",
        doi: "",
        url: "",
        projectDescription: "",
        fundStatus: {},
        projectBudget: "",
        fundingAgency: "",
        documentsUpload: "",
        rolePlayed: [],
        place: "",
        country: "",
        assignee: "",
        issuingauthority: "",
        patentnumber: "",
        fillingdate: "",
        applicationnumber: "",
        issuedate: "",
        reference: "",
        legalstatus: "",
        identifier: "",
        repository: "",
        repositorylocation: "",
        version: ""
    }), []);
    const [values, fields, handleChange, { validateValues, reset, updateValue }] = useFieldItem(ResearchFormElements, initialValues, { onValueChange });
    const [isFunded, SetFunded] = useState(true);
    const [loader, Setloader] = useState(false);
    const dispatch = useDispatch();
    const { selectedIndex, data } = props;
    const [authorList, setauthorList] = useState<any>([])
    
    useEffect(() => {
        if (selectedIndex !== null && selectedIndex >= 0 && data && data.research_project && data.research_project.length > 0) {
            let ResearchWorkData = data?.research_project[selectedIndex];
            SetFunded(ResearchWorkData?.funding_status?.value == "funded" ? false : true)
            //updateValue({ publicationtype: ResearchWorkData?.publication_type, title: ResearchWorkData?.project_name, projectDescription: ResearchWorkData?.project_description, documentsUpload: ResearchWorkData?.documents, fundStatus: ResearchWorkData?.funding_status, projectBudget: ResearchWorkData?.project_budget, fundingAgency: ResearchWorkData?.funding_agency, doi: ResearchWorkData?.doi, publishedDate: ResearchWorkData?.project_status.value != "inprogress" ? new Date(ResearchWorkData?.published_date) : "", journal: ResearchWorkData?.journal, projectStatus: ResearchWorkData?.project_status, rolePlayed: ResearchWorkData?.role_played, author: ResearchWorkData?.authors })
            let rolesPlayed: any = [];
            ResearchWorkData?.role_played.map(item => {
                rolesPlayed.push({ label: item, value: item })
            })
            let date = dayjs(ResearchWorkData?.published_date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
            let filldate = dayjs(ResearchWorkData?.filling_date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
            let issuedate = dayjs(ResearchWorkData?.issue_date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");

         let unit = ResearchFormElements.unitBudget.values.filter(item => item.value == ResearchWorkData?.project_budget.split(" ")[0]);
         
            updateValue({
                publicationtype: ResearchWorkData?.publication_type,
                projectStatus: ResearchWorkData?.project_status,
                author: ResearchWorkData?.authors,
                title: ResearchWorkData?.project_name,
                sectiontitle: ResearchWorkData?.section_title,
                journalname: ResearchWorkData?.journal,
                publishername: ResearchWorkData?.publisher_name,
                publishedDate: dayjs(date, "DD/MM/YYYY"),
                volume: ResearchWorkData?.volume,
                issue: ResearchWorkData?.issue,
                pageNo: ResearchWorkData?.page_no,
                doi: ResearchWorkData?.doi,
                url: ResearchWorkData?.url,
                projectDescription: ResearchWorkData?.project_description,
                fundStatus: ResearchWorkData?.funding_status,
                unitBudget: unit[0],
                projectBudget: ResearchWorkData?.project_budget.split(" ")[1],
                fundingAgency: ResearchWorkData?.funding_agency,
                documentsUpload: ResearchWorkData?.documents,
                rolePlayed: rolesPlayed,
                place: ResearchWorkData?.place,
                country: ResearchWorkData?.country,
                assignee: ResearchWorkData?.assignee,
                issuingauthority: ResearchWorkData?.issuing_authority,
                patentnumber: ResearchWorkData?.patent_number,
                fillingdate: dayjs(filldate, "DD/MM/YYYY"),
                applicationnumber: ResearchWorkData?.application_number,
                issuedate: dayjs(issuedate, "DD/MM/YYYY"),
                reference: ResearchWorkData?.references,
                legalstatus: ResearchWorkData?.legal_status,
                identifier: ResearchWorkData?.identifier,
                repository: ResearchWorkData?.repository,
                repositorylocation: ResearchWorkData?.repository_location,
                version: ResearchWorkData?.version
            })
        }
    }, [])



    /**
  * Upload the selected file on success update media URL and new_doc as true
  * @param name 
  * @param value 
  * @param values 
  * @param fieldValues 
  * @returns 
  */
    function onValueChange(name, value, values, fieldValues = null) {
        let resetValue = {};
        if (name == "fundStatus") {
            if (value.label == "Funded") {
                SetFunded(false)
            } else {
                values["fundingAgency"] = "";
                values["projectBudget"] = "";
                SetFunded(true)
            }
        }
        /**
         * Upload file
         */
        if (name == "documentsUpload") {
            console.log(value)
            fileUpload(value)
        } else {
            updateValue({ [name]: value })
        }

        if (name == "publicationtype") {
            values["projectStatus"] = {};
            values["author"] = [];
            values["title"] = "";
            values["sectiontitle"] = "";
            values["journalname"] = "";
            values["publishername"] = "";
            values["publishedDate"] = null
            values["volume"] = ""
            values["issue"] = ""
            values["pageNo"] = ""
            values["doi"] = ""
            values['url'] = ""
            values["projectDescription"] = ""
            values["fundStatus"] = {}
            values["unitBudget"] = {}
            values["projectBudget"] = ""
            values["fundingAgency"] = ""
            values["documentsUpload"] = ""
            values["rolePlayed"] = []
            values["place"] = ""
            values["country"] = ""
            values["assignee"] = ""
            values["issuingauthority"] = ""
            values["patentnumber"] = ""
            values["fillingdate"] = ""
            values["applicationnumber"] = ""
            values["issuedate"] = ""
            values["reference"] = ""
            values["legalstatus"] = ""
            values["identifier"] = ""
            values["repository"] = ""
            values["repositorylocation"] = ""
            values["version"] = ""
        }
    

    return [{
        ...values,
        ...resetValue,
        [name]: value,
    }]
}

/**
 * upload document 
 * argument file
 */
const fileUpload = async (value) => {
    const formData = new FormData();
    formData.append("file", value[0]);
    try {
        Setloader(true);
        let response = await uploadSelectedFile(value["0"].name.substr(value["0"].name.lastIndexOf('.') + 1), formData)
        if (response.success) {
            console.log(response)
            updateValue({ "documentsUpload": [...values.documentsUpload, response.mediaUrl] })
            //showToaster(toasterTypes.SUCCESS,"File Uploaded successfully")
        }
        Setloader(false);

    } catch (e: any) {
        Setloader(false);
        throw e
    }
}

/**
 * on submit 
 */
const checkSubmit = async () => {
    let roleArray: any = [];
    values.rolePlayed.map(item => {
        roleArray.push(item.label)
    })
    if (validateValues(["publicationtype", "title", "projectDescription","doi"])) {
        showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
    } else {
        console.log(values)
        let finalData = {
            "publication_type": values.publicationtype,
            "project_name": values.title,
            "project_description": values.projectDescription,
            "project_budget": (values.unitBudget ? values.unitBudget.value + " " : "") + (values.projectBudget),
            "funding_agency": values.fundingAgency,
            "role_played": roleArray,
            "documents": values.documentsUpload ? values.documentsUpload : [],
            "doi": values.doi,
            "citation_count": 0,
            "authors": values.author,
            "published_date": values.publishedDate ? values.publishedDate['$d'] : null,
            "volume": values.volume,
            "page_no": values.pageNo,
            "journal": values.journalname,
            "project_status": values.projectStatus,
            "funding_status": values.fundStatus,
            "section_title": values.sectiontitle,
            "publisher_name": values.publishername,
            "issue": values.issue,
            "url": values.url,
            "place": values.place,
            "country": values.country,
            "assignee": values.assignee,
            "issuing_authority": values.issuingauthority,
            "patent_number": values.patentnumber,
            "filling_date": values.fillingdate ? values.fillingdate['$d'] : null,
            "application_number": values.applicationnumber,
            "issue_date": values.issuedate ? values.issuedate['$d'] : null,
            "references": values.reference,
            "legal_status": values.legalstatus,
            "identifier": values.identifier,
            "repository": values.repository,
            "repository_location": values.repositorylocation,
            "version": values.version
        }

        try {
            //dispatch(setLoading(true));

            if (selectedIndex !== null && selectedIndex >= 0) {
                let response = await updateResearchWork(finalData, data.research_project[selectedIndex].id);
            } else {
                let response = await addResearchWork(finalData);
                if (response.success) {
                    showToaster(toasterTypes.SUCCESS, response.message);
                }
            }
            props.onClose('callAPI');
            //dispatch(setLoading(false));

        } catch (e: any) {
            throw e
            dispatch(setLoading(false));

        }
    }
}

/**
 * delete item from array documents
 */
const deleteFile = (index) => {
    values.documentsUpload.splice(index, 1)
    updateValue({ documentsUpload: values.documentsUpload })
}

return (
    <ModalComponent
        width={"100%"}
        isOpen={true}
        onClose={() => props.onClose()}
        header="Research Work"
        centered={true}
        customClass='profile-modal-research'
        body={
            <div>
                <Row>
                    <Col md={4}>
                        <FieldItem
                            {...ResearchFormElements.publicationtype}
                            value={values.publicationtype}
                            onChange={(...e) => handleChange(ResearchFormElements.publicationtype.name, ...e)}
                            touched={fields.publicationtype && fields.publicationtype.hasError}
                            error={fields.publicationtype && fields.publicationtype.errorMsg}
                        />
                    </Col>

                    {
                        values.publicationtype.label == "Journal Article" || values.publicationtype.label == "Book" || values.publicationtype.label == "Book Section" || values.publicationtype.label == "Pre Print" ?
                            <Col md={4}>
                                <FieldItem
                                    {...ResearchFormElements.projectStatus}
                                    value={values.projectStatus}
                                    onChange={(...e) => handleChange(ResearchFormElements.projectStatus.name, ...e)}
                                    touched={fields.projectStatus && fields.projectStatus.hasError}
                                    error={fields.projectStatus && fields.projectStatus.errorMsg}
                                />
                            </Col>
                            : ""
                    }

                    <Col md={4}>
                        <FieldItem
                            {...ResearchFormElements.author}
                            value={values.author}
                            values={authorList}
                            onChange={(...e) => handleChange(ResearchFormElements.author.name, ...e)}
                            touched={fields.author && fields.author.hasError}
                            error={fields.author && fields.author.errorMsg}
                        />
                    </Col>
                    <Col md={4}>
                        <FieldItem
                            {...ResearchFormElements.title}
                            value={values.title}
                            onChange={(...e) => handleChange(ResearchFormElements.title.name, ...e)}
                            touched={fields.title && fields.title.hasError}
                            error={fields.title && fields.title.errorMsg}
                        />
                    </Col>

                    {
                        values.publicationtype.label == "Book Section" ?
                            <Col md={4}>
                                <FieldItem
                                    {...ResearchFormElements.sectiontitle}
                                    value={values.sectiontitle}
                                    onChange={(...e) => handleChange(ResearchFormElements.sectiontitle.name, ...e)}
                                    touched={fields.sectiontitle && fields.sectiontitle.hasError}
                                    error={fields.sectiontitle && fields.sectiontitle.errorMsg}
                                />
                            </Col>
                            : ""
                    }

                    {
                        values.publicationtype.label != "Funding Proposal" && values.publicationtype.label != "Patent" ?
                            <Col md={4}>
                                <FieldItem
                                    {...ResearchFormElements.journalname}
                                    value={values.journalname}
                                    label={values.publicationtype.label == "Blog Post" ? "Website Name" : values.publicationtype.label == "Poster" ? "Conference Name" : values.publicationtype.label == "Thesis" ? "University Name & Place" : "Journal Name"}
                                    placeholder={values.publicationtype.label == "Blog Post" ? "Website Name" : values.publicationtype.label == "Poster" ? "Conference Name" : values.publicationtype.label == "Thesis" ? "University Name & Place" : "Journal Name"}
                                    onChange={(...e) => handleChange(ResearchFormElements.journalname.name, ...e)}
                                    touched={fields.journalname && fields.journalname.hasError}
                                    error={fields.journalname && fields.journalname.errorMsg}
                                />
                            </Col>
                            : ""
                    }

                    {
                        values.publicationtype.label != "Funding Proposal" && values.publicationtype.label != "Poster" && values.publicationtype.label != "Thesis" && values.publicationtype.label != "Patent" ?
                            <Col md={4}>
                                <FieldItem
                                    {...ResearchFormElements.publishername}
                                    value={values.publishername}
                                    onChange={(...e) => handleChange(ResearchFormElements.publishername.name, ...e)}
                                    touched={fields.publishername && fields.publishername.hasError}
                                    error={fields.publishername && fields.publishername.errorMsg}
                                />
                            </Col>
                            : ""
                    }
                    {
                        (values.publicationtype.label == "Journal Article" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Book" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Book Section" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Pre Print" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Blog Post") ||
                            (values.publicationtype.label == "Conference Paper") ||
                            (values.publicationtype.label == "Funding Proposal") ||
                            (values.publicationtype.label == "Poster") ||
                            (values.publicationtype.label == "Code") ||
                            (values.publicationtype.label == "Thesis") ||
                            (values.publicationtype.label == "Cover Page") ||
                            (values.publicationtype.label == "Data") ||
                            (values.publicationtype.label == "Experiment Findings") ||
                            (values.publicationtype.label == "Methods") ||
                            (values.publicationtype.label == "Negative Result") ||
                            (values.publicationtype.label == "Patent")
                            ?
                            <Col md={4}>
                                <FieldItem
                                    {...ResearchFormElements.publishedDate}
                                    value={values.publishedDate}
                                    onChange={(...e) => handleChange(ResearchFormElements.publishedDate.name, ...e)}
                                    touched={fields.publishedDate && fields.publishedDate.hasError}
                                    error={fields.publishedDate && fields.publishedDate.errorMsg}
                                />
                            </Col>
                            : ""
                    }

                    {
                        (values.publicationtype.label == "Journal Article" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Book" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Book Section" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Pre Print" && values.projectStatus.label == "Published") ?
                            <Col md={4}>
                                <FieldItem
                                    {...ResearchFormElements.volume}
                                    value={values.volume}
                                    onChange={(...e) => handleChange(ResearchFormElements.volume.name, ...e)}
                                    touched={fields.volume && fields.volume.hasError}
                                    error={fields.volume && fields.volume.errorMsg}
                                />
                            </Col>
                            : ""
                    }

                    {
                        (values.publicationtype.label == "Journal Article" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Book" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Book Section" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Pre Print" && values.projectStatus.label == "Published") ?
                            <Col md={4}>
                                <FieldItem
                                    {...ResearchFormElements.issue}
                                    value={values.issue}
                                    onChange={(...e) => handleChange(ResearchFormElements.issue.name, ...e)}
                                    touched={fields.issue && fields.issue.hasError}
                                    error={fields.issue && fields.issue.errorMsg}
                                />
                            </Col>
                            : ""
                    }
                    {
                        (values.publicationtype.label == "Journal Article" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Book" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Book Section" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Pre Print" && values.projectStatus.label == "Published") ?
                            <Col md={4}>
                                <FieldItem
                                    {...ResearchFormElements.pageNo}
                                    value={values.pageNo}
                                    onChange={(...e) => handleChange(ResearchFormElements.pageNo.name, ...e)}
                                    touched={fields.pageNo && fields.pageNo.hasError}
                                    error={fields.pageNo && fields.pageNo.errorMsg}
                                />
                            </Col>
                            : ""
                    }

                    {
                        (values.publicationtype.label == "Journal Article" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Book" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Book Section" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Pre Print" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Conference Paper") ||
                            (values.publicationtype.label == "Poster")
                            ?
                            <Col md={4}>
                                <FieldItem
                                    {...ResearchFormElements.doi}
                                    value={values.doi}
                                    onChange={(...e) => handleChange(ResearchFormElements.doi.name, ...e)}
                                    touched={fields.doi && fields.doi.hasError}
                                    error={fields.doi && fields.doi.errorMsg}
                                />
                            </Col>
                            : ""
                    }

                    {
                        (values.publicationtype.label == "Journal Article" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Book" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Book Section" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Pre Print" && values.projectStatus.label == "Published") ||
                            (values.publicationtype.label == "Blog Post") ||
                            (values.publicationtype.label == "Conference Paper") ||
                            (values.publicationtype.label == "Funding Proposal") ||
                            (values.publicationtype.label == "Poster") ||
                            (values.publicationtype.label == "Thesis") ||
                            (values.publicationtype.label == "Cover Page") ||
                            (values.publicationtype.label == "Code") ||
                            (values.publicationtype.label == "Data") ||
                            (values.publicationtype.label == "Experiment Findings") ||
                            (values.publicationtype.label == "Methods") ||
                            (values.publicationtype.label == "Negative Result") ||
                            (values.publicationtype.label == "Patent")
                            ?
                            <Col md={4}>
                                <FieldItem
                                    {...ResearchFormElements.url}
                                    value={values.url}
                                    onChange={(...e) => handleChange(ResearchFormElements.url.name, ...e)}
                                    touched={fields.url && fields.url.hasError}
                                    error={fields.url && fields.url.errorMsg}
                                />
                            </Col>
                            : ""
                    }

                    {
                        values.publicationtype.label != "Poster" ?
                            <Col md={12}>
                                <FieldItem
                                    {...ResearchFormElements.projectDescription}
                                    value={values.projectDescription}
                                    onChange={(...e) => handleChange(ResearchFormElements.projectDescription.name, ...e)}
                                    touched={fields.projectDescription && fields.projectDescription.hasError}
                                    error={fields.projectDescription && fields.projectDescription.errorMsg}
                                />
                            </Col>
                            : ""
                    }

                    {
                        values.publicationtype.label != "Blog Post" ?
                            <>
                                <Col md={3}>
                                    <FieldItem
                                        {...ResearchFormElements.fundStatus}
                                        value={values.fundStatus}
                                        onChange={(...e) => handleChange(ResearchFormElements.fundStatus.name, ...e)}
                                        touched={fields.fundStatus && fields.fundStatus.hasError}
                                        error={fields.fundStatus && fields.fundStatus.errorMsg}
                                    />
                                </Col>
                                <Col md={1}>
                                    <FieldItem
                                        {...ResearchFormElements.unitBudget}
                                        value={values.unitBudget}
                                        isDisabled={isFunded}
                                        onChange={(...e) => handleChange(ResearchFormElements.unitBudget.name, ...e)}
                                        touched={fields.unitBudget && fields.unitBudget.hasError}
                                        error={fields.unitBudget && fields.unitBudget.errorMsg}
                                    />
                                </Col>
                                <Col md={3}>
                                    <FieldItem
                                        {...ResearchFormElements.projectBudget}
                                        value={values.projectBudget}
                                        isDisabled={isFunded}
                                        onChange={(...e) => handleChange(ResearchFormElements.projectBudget.name, ...e)}
                                        touched={fields.projectBudget && fields.projectBudget.hasError}
                                        error={fields.projectBudget && fields.projectBudget.errorMsg}
                                    />
                                </Col>

                                <Col md={4}>
                                    <FieldItem
                                        {...ResearchFormElements.fundingAgency}
                                        value={values.fundingAgency}
                                        isDisabled={isFunded}
                                        onChange={(...e) => handleChange(ResearchFormElements.fundingAgency.name, ...e)}
                                        touched={fields.fundingAgency && fields.fundingAgency.hasError}
                                        error={fields.fundingAgency && fields.fundingAgency.errorMsg}
                                    />
                                </Col>
                            </>
                            : ""
                    }
                    <Col md={12}>
                        <FieldItem
                            className="upload-research"
                            {...ResearchFormElements.documentsUpload}
                            value={values.documentsUpload}
                            onChange={(...e) => handleChange(ResearchFormElements.documentsUpload.name, ...e)}
                            touched={fields.documentsUpload && fields.documentsUpload.hasError}
                            error={fields.documentsUpload && fields.documentsUpload.errorMsg}
                        />
                    </Col>
                    <div className="document-data">
                        {
                            values.documentsUpload && values.documentsUpload.map((item, i) => {
                                return <span className="doc-item">{item.name || item?.substr(item?.lastIndexOf('/') + 1)}<CloseCircleFilled onClick={() => deleteFile(i)} /></span>
                            })
                        }
                    </div>
                    {
                        (values.publicationtype.label != "Code") ||
                            (values.publicationtype.label != "Cover Page") ||
                            (values.publicationtype.label != "Data") ||
                            (values.publicationtype.label != "Experiment Findings") ||
                            (values.publicationtype.label != "Methods") ||
                            (values.publicationtype.label != "Negative Result") ||
                            (values.publicationtype.label != "Patent") ||
                            (values.publicationtype.label != "Raw Data") ||
                            (values.publicationtype.label != "Technical Report")
                            ?
                            <Col md={12}>
                                <FieldItem
                                    {...ResearchFormElements.rolePlayed}
                                    value={values.rolePlayed}
                                    onChange={(...e) => handleChange(ResearchFormElements.rolePlayed.name, ...e)}
                                    touched={fields.rolePlayed && fields.rolePlayed.hasError}
                                    error={fields.rolePlayed && fields.rolePlayed.errorMsg}
                                />
                            </Col>
                            : ""
                    }

                    <Col md={4}>
                        <FieldItem
                            {...ResearchFormElements.place}
                            value={values.place}
                            onChange={(...e) => handleChange(ResearchFormElements.place.name, ...e)}
                            touched={fields.place && fields.place.hasError}
                            error={fields.place && fields.place.errorMsg}
                        />
                    </Col>
                    {
                        values.publicationtype.label == "Patent" ?
                            <>
                                <Col md={4}>
                                    <FieldItem
                                        {...ResearchFormElements.country}
                                        value={values.country}
                                        onChange={(...e) => handleChange(ResearchFormElements.country.name, ...e)}
                                        touched={fields.country && fields.country.hasError}
                                        error={fields.country && fields.country.errorMsg}
                                    />
                                </Col>
                                <Col md={4}>
                                    <FieldItem
                                        {...ResearchFormElements.assignee}
                                        value={values.assignee}
                                        onChange={(...e) => handleChange(ResearchFormElements.assignee.name, ...e)}
                                        touched={fields.assignee && fields.assignee.hasError}
                                        error={fields.assignee && fields.assignee.errorMsg}
                                    />
                                </Col>
                                <Col md={4}>
                                    <FieldItem
                                        {...ResearchFormElements.issuingauthority}
                                        value={values.issuingauthority}
                                        onChange={(...e) => handleChange(ResearchFormElements.issuingauthority.name, ...e)}
                                        touched={fields.issuingauthority && fields.issuingauthority.hasError}
                                        error={fields.issuingauthority && fields.issuingauthority.errorMsg}
                                    />
                                </Col>
                                <Col md={4}>
                                    <FieldItem
                                        {...ResearchFormElements.patentnumber}
                                        value={values.patentnumber}
                                        onChange={(...e) => handleChange(ResearchFormElements.patentnumber.name, ...e)}
                                        touched={fields.patentnumber && fields.patentnumber.hasError}
                                        error={fields.patentnumber && fields.patentnumber.errorMsg}
                                    />
                                </Col>
                                <Col md={4}>
                                    <FieldItem
                                        {...ResearchFormElements.fillingdate}
                                        value={values.fillingdate}
                                        onChange={(...e) => handleChange(ResearchFormElements.fillingdate.name, ...e)}
                                        touched={fields.fillingdate && fields.fillingdate.hasError}
                                        error={fields.fillingdate && fields.fillingdate.errorMsg}
                                    />
                                </Col>
                                <Col md={4}>
                                    <FieldItem
                                        {...ResearchFormElements.applicationnumber}
                                        value={values.applicationnumber}
                                        onChange={(...e) => handleChange(ResearchFormElements.applicationnumber.name, ...e)}
                                        touched={fields.applicationnumber && fields.applicationnumber.hasError}
                                        error={fields.applicationnumber && fields.applicationnumber.errorMsg}
                                    />
                                </Col>
                                <Col md={4}>
                                    <FieldItem
                                        {...ResearchFormElements.issuedate}
                                        value={values.issuedate}
                                        onChange={(...e) => handleChange(ResearchFormElements.issuedate.name, ...e)}
                                        touched={fields.issuedate && fields.issuedate.hasError}
                                        error={fields.issuedate && fields.issuedate.errorMsg}
                                    />
                                </Col>
                                <Col md={4}>
                                    <FieldItem
                                        {...ResearchFormElements.reference}
                                        value={values.reference}
                                        onChange={(...e) => handleChange(ResearchFormElements.reference.name, ...e)}
                                        touched={fields.reference && fields.reference.hasError}
                                        error={fields.reference && fields.reference.errorMsg}
                                    />
                                </Col>
                                <Col md={4}>
                                    <FieldItem
                                        {...ResearchFormElements.legalstatus}
                                        value={values.legalstatus}
                                        onChange={(...e) => handleChange(ResearchFormElements.legalstatus.name, ...e)}
                                        touched={fields.legalstatus && fields.legalstatus.hasError}
                                        error={fields.legalstatus && fields.legalstatus.errorMsg}
                                    />
                                </Col>
                            </>
                            : ""
                    }
                    {
                        values.publicationtype.label == "Data" || values.publicationtype.label == "Code" ?
                            <>
                                <Col md={4}>
                                    <FieldItem
                                        {...ResearchFormElements.identifier}
                                        value={values.identifier}
                                        onChange={(...e) => handleChange(ResearchFormElements.identifier.name, ...e)}
                                        touched={fields.identifier && fields.identifier.hasError}
                                        error={fields.identifier && fields.identifier.errorMsg}
                                    />
                                </Col>
                                <Col md={4}>
                                    <FieldItem
                                        {...ResearchFormElements.repository}
                                        value={values.repository}
                                        onChange={(...e) => handleChange(ResearchFormElements.repository.name, ...e)}
                                        touched={fields.repository && fields.repository.hasError}
                                        error={fields.repository && fields.repository.errorMsg}
                                    />
                                </Col>
                                <Col md={4}>
                                    <FieldItem
                                        {...ResearchFormElements.repositorylocation}
                                        value={values.repositorylocation}
                                        onChange={(...e) => handleChange(ResearchFormElements.repositorylocation.name, ...e)}
                                        touched={fields.repositorylocation && fields.repositorylocation.hasError}
                                        error={fields.repositorylocation && fields.repositorylocation.errorMsg}
                                    />
                                </Col>

                                <Col md={4}>
                                    <FieldItem
                                        {...ResearchFormElements.version}
                                        value={values.version}
                                        onChange={(...e) => handleChange(ResearchFormElements.version.name, ...e)}
                                        touched={fields.version && fields.version.hasError}
                                        error={fields.version && fields.version.errorMsg}
                                    />
                                </Col>
                            </>
                            : ""
                    }
                </Row>


            </div>
        }
        footer={
            <div>
                <CustomButton type="primary" isDisabled={false} text="Save" onClick={() => checkSubmit()}></CustomButton>
            </div>
        }
    />
)
}