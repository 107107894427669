import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { valueContainerCSS } from "react-select/dist/declarations/src/components/containers";
import { Button } from "reactstrap";
import { setLoading } from "../../store/slices/auth";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalComponent from "../common/Modal/modal";
import { getForms, getFormVariables, getGroups } from "../dataCollection/apiController";
import AddVariableComponent from "./AddVariableComponent";
import CategoricalVariable from "./CategoricalVariable";
import { GroupFormElements } from "./FieldElements";
import { CloseOutlined } from "@ant-design/icons";
import MonacoEditor from "./CodeEditor";

export default function GroupVariable(props) {
    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem(GroupFormElements, {}, { onValueChange });
    const [variableList, SetVariableList] = useState<any>([]);
    const [selectedgroupType, SetGroupType] = useState("");
    const [projectData, SetProjectData] = useState<any>([]);
    const [groupData, SetGroup] = useState<any>([]);
    const [itemCount, SetItemCount] = useState(0);
    const [formList, SetFormList] = useState<any>([]);
    const [formvariables, setformVariables] = useState<any>([]);
    const [formvariablesValue, setformVariablesValue] = useState<any>([]);
    const dispatch = useDispatch();
    let componentObj = {};
    const [varaibleData, Setvariabledata] = useState<any>([]);
    const [isSuggestion, SetSuggestion] = useState(false);
    const [numericValues, SetNurmericValues] = useState<any>([]);

    /**
    * on value change update form data
    * @param name 
    * @param value 
    * @param values 
    * @param fieldValues 
    * @returns 
    */
    function onValueChange(name, value, values, fieldValues = null) {
        let resetValue = {};
        console.log(name)
        if (name == "grouptype") {
            SetGroupType(value.label)
            SetVariableList([])
            values.variable = {}
            values.formtype = {}
            values.newcolumnname = [];
        } else if (name == "formtype") {
            values.variable = {}
            values.newcolumnname = [];
            fetchFormVariables(value);
            SetVariableList([]);
            SetItemCount(0);
        } else if (name == "variable") {
            SetItemCount(0);
            SetVariableList([]);
            SetItemCount(itemCount + 1);
            values.newcolumnname = [];
            // let componentObj = formvariablesValue.filter(item => item.field_name == value.label);
            // SetVariableList(componentObj)
            console.log(variableList)
        }
        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }

    useEffect(() => {
        SetProjectData(props.data)
        fetchGroups(props.data.TopicID)
    }, [])

    useEffect(() => {
        console.log(variableList)
    }, [variableList, selectedgroupType, itemCount])

    /**
     * get variables data
     * @param formData 
     */
    const fetchFormVariables = async (formData) => {
        try {
            dispatch(setLoading(true));
            let response = await getFormVariables(formData.value);
            setformVariables([]);
            setformVariablesValue(response.data);
            if (values["grouptype"].label == "Categorical") {
                response.data.filter(itemfield => itemfield.field_type != "textBox" && itemfield.field_type != "number").map((item, i) => {
                    let Obj = {
                        label: item.field_name,
                        value: item.id
                    }
                    setformVariables(prevState => [...prevState, Obj]);
                })
            } else if (values["grouptype"].label == "Numerical") {
                console.log(values["grouptype"])
                response.data.filter(itemfield => itemfield.field_type == "number").map((item, i) => {
                    let Obj = {
                        label: item.field_name,
                        value: item.id
                    }
                    setformVariables(prevState => [...prevState, Obj]);
                })
            } else if (values["grouptype"].label == "Compute") {
                console.log(values["grouptype"])
                response.data.map((item, i) => {
                    let Obj = {
                        label: item.field_name,
                        value: item.id
                    }
                    setformVariables(prevState => [...prevState, Obj]);
                })
            }

            dispatch(setLoading(false));
        } catch (e: any) {
            setformVariables([]);
            dispatch(setLoading(false));
        }
    }

    /**
     * remove add value component
     */
    const removeComponent = (id) => {
        let updatedVariables = variableList.filter(item => item.key != id);
        delete values["values" + id]
        delete values["newcode" + id]
        //updateValue({["values"+id]:"",["newcode"+id]:""});
        SetVariableList(updatedVariables);
        console.log(values);
        SetItemCount(itemCount - 1);
    }

    /**
    * add value component to state
    */
    const addValuesComponent = async () => {
        if (values.variable?.value || values.grouptype.value == "Advanced_compute") {
            SetItemCount(itemCount + 1)
            await formvariablesValue.filter(item => item.field_name == values['variable'].label).map(itemvalue => {
                componentObj['key'] = variableList[variableList?.length - 1] ? variableList[variableList.length - 1].key + 1 : 1;
                componentObj['newcode'] = "";
                componentObj['variablelist'] = [];
                componentObj['field_use_type'] = itemvalue.field_use_type;
                componentObj['field_values'] = itemvalue.field_use_type;
                let fieldValues: any = [];
                itemvalue.field_value.map((item, i) => {
                    let Obj = {
                        label: item,
                        value: item
                    }
                    fieldValues.push(Obj)
                })
                componentObj['field_value'] = fieldValues;
            });
            SetSuggestion(variableList.length == 0 ? true : false)
            SetVariableList(prevState => [...prevState, componentObj]);
        } else {
            updateError("variable", { hasError: true, errorMsg: "Please select variable" });
        }
    }

    /**
     * get groups
     * @param id 
     */
    const fetchGroups = async (id) => {
        try {
            dispatch(setLoading(true));
            let tempGroups = await getGroups(id);
            SetGroup(tempGroups?.data || []);
            fetchForms(tempGroups?.data[0].id);
            dispatch(setLoading(false));
        } catch (e: any) {
            SetGroup([]);
            dispatch(setLoading(false));
        }
    };

    /**
     * fetch form list
     * @param index 
     * @param id 
     */
    const fetchForms = async (id) => {
        try {
            dispatch(setLoading(true));
            let tempForms = await getForms(id);
            SetFormList([]);
            tempForms.map((item, i) => {
                let Obj = {
                    label: item.form_name,
                    value: item.id
                }
                SetFormList(prevState => [...prevState, Obj]);
            })
            SetSuggestion(true)
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    };

    /**
     * get values from the add variable component
     */
    const getValues = (name, value, code) => {
        console.log(name, value, code)
        let isExist = numericValues.filter(item => item.key == name)
        console.log(isExist.length)
        if (isExist.length != 0) {
            let removeItem = numericValues.filter(item => item.key != name)
            SetNurmericValues(removeItem)
            SetNurmericValues(prevState => [...prevState, { "key": name, "oldValues": [code], "newValue": value }])

        } else {
            SetNurmericValues(prevState => [...prevState, { "key": name, "oldValues": [code], "newValue": value }])
        }
        console.log(numericValues)
    }

    /**
     * remove item from the array of numeric and compute objects
     */
    const removeItem = (key) => {
        console.log(key,variableList)
        let removeItem = numericValues.filter(item => item.key != "newcode"+key)
        SetNurmericValues(removeItem)
        let updatedVariables = variableList.filter(item => item.key != key);
        //updateValue({["values"+id]:"",["newcode"+id]:""});
        SetVariableList(updatedVariables);
        SetItemCount(itemCount - 1);
    }
    /**
     * recreate the array of items for submissions
     * call to API
     */
    const saveData = () => {
        let regroupfields: any = [];
        if (values.grouptype.value == "category_grouping") {
            variableList.map((item, index) => {
                let oldCodesVal: any = [];
                values["values" + item.key].map(items => {
                    console.log(items)
                    oldCodesVal.push(items.label)
                })
                regroupfields.push({
                    newCode: values["newcode" + item.key],
                    oldCodes: oldCodesVal
                })
            })

        } else {
            numericValues.map((item, index) => {
                let oldCodesVal: any = item.oldValues;

                regroupfields.push({
                    newCode: item.newValue,
                    oldCodes: oldCodesVal
                })
            })
        }
        let Obj = {
            "fromVariable": values.variable.label,
            "newVariable": values.newcolumnname,
            "regroupingfields": regroupfields
        }
        let finalArray: any = [];
        finalArray.push({ "usernumber": projectData.UserNumber, "topicid": projectData.TopicID, [values.grouptype.value]: Obj })

        console.log(finalArray)

    }

    return (
        <>
            <ModalComponent
                width={window.innerWidth > 700 ? "1000px" : "350px"}
                isOpen={true}
                onClose={() => props.onClose()}
                header="Group Variables"
                centered={true}
                customClass='invite-modal group-modal'
                body={<>
                    <Row>
                        <Col md={4}>
                            <FieldItem
                                {...GroupFormElements.grouptype}
                                value={values.grouptype}
                                values={GroupFormElements.grouptype.values}
                                onChange={(...e) => handleChange(GroupFormElements.grouptype.name, ...e)}
                                touched={fields.grouptype && fields.grouptype.hasError}
                                error={fields.grouptype && fields.grouptype.errorMsg}
                            />
                        </Col>
                        {values && values.grouptype?.value != "Advanced_compute" ?
                            <>
                                <Col md={4}>
                                    <FieldItem
                                        {...GroupFormElements.formType}
                                        value={values.formtype}
                                        values={formList ? formList : []}
                                        onChange={(...e) => handleChange(GroupFormElements.formType.name, ...e)}
                                        touched={fields.formType && fields.formType.hasError}
                                        error={fields.formType && fields.formType.errorMsg}
                                        isMandatory={selectedgroupType == "Compute" ? false : true}

                                    />
                                </Col>
                                <Col md={4}>
                                    <FieldItem
                                        {...GroupFormElements.variable}
                                        value={values.variable}
                                        values={formvariables}
                                        isMandatory={selectedgroupType == "Compute" ? false : true}
                                        onChange={(...e) => handleChange(GroupFormElements.variable.name, ...e)}
                                        touched={fields.variable && fields.variable.hasError}
                                        error={fields.variable && fields.variable.errorMsg}
                                    />
                                </Col>
                            </>
                            : ""}

                    </Row>
                    <hr></hr>
                    <div className="add-variable">
                        <Row>
                            <Col md={10}>
                                <h6>{values?.variable?.label || ""}</h6>
                            </Col>
                            <Col md={2}>
                                <span className="add-value" onClick={() => addValuesComponent()}>Add Value</span>
                            </Col>
                        </Row>
                        {
                            variableList.length != 0 ?
                                <Row>
                                    <Col md={7}>
                                        <span>Values</span>
                                    </Col>
                                    <Col md={5}>
                                        <span>New Value / Code</span>
                                    </Col>
                                </Row>
                                :
                                ""
                        }

                        {
                            variableList && variableList.map((item, index) => {
                                return <>
                                    {
                                        selectedgroupType == "Categorical" ?
                                            <div className="categorical-container">
                                                <Row>
                                                    <Col md={7}>
                                                        <FieldItem
                                                            name={"values" + index}
                                                            type={FIELD_TYPES.MULTI_SELECT}
                                                            value={values["values" + item.key]}
                                                            values={item.field_value}
                                                            onChange={(...e) => handleChange("values" + item.key, ...e)}
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <FieldItem
                                                            name={"newcode" + index}
                                                            value={values["newcode" + item.key]}
                                                            values={[]}
                                                            onChange={(...e) => handleChange("newcode" + item.key, ...e)}
                                                            touched={fields["newcode" + item.key] && fields["newcode" + item.key].hasError}
                                                            error={fields["newcode" + item.key] && fields["newcode" + item.key].errorMsg}
                                                        />
                                                    </Col>
                                                    <Col md={1}>
                                                        <CloseOutlined style={{ color: "red" }} onClick={() => removeComponent(item.key)} />
                                                    </Col>
                                                </Row>
                                            </div>
                                            :
                                            <AddVariableComponent
                                                data={item}
                                                variablevalue={values?.variable?.label}
                                                grouptype={values["grouptype"].label}
                                                variable={formvariables}
                                                forms={formList}
                                                suggest={isSuggestion}
                                                remove={removeItem}
                                                getData={getValues} />
                                    }
                                </>

                            })
                        }
                    </div>
                    <hr></hr>
                    <FieldItem
                        {...GroupFormElements.newcolumnname}
                        value={values.newcolumnname}
                        values={[]}
                        onChange={(...e) => handleChange(GroupFormElements.newcolumnname.name, ...e)}
                        touched={fields.newcolumnname && fields.newcolumnname.hasError}
                        error={fields.newcolumnname && fields.newcolumnname.errorMsg}
                    />


                </>}
                footer={
                    <CustomButton type="primary" className='save-button' text="Save" onClick={() => saveData()}></CustomButton>
                }
            />
        </>
    )
}