import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { setLoading } from "../../store/slices/auth";
import CustomTable from "../../widgets/table";
import { getGroups } from "../dataCollection/apiController";
import Tabs from "../sampleSize/Tabs";
import GroupVariable from "./GroupVariablesModal";
import { ArrowLeftOutlined } from "@ant-design/icons";
import * as monaco from 'monaco-editor';


export default function DataAnalysis(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [projectData, setprojectData] = useState<any | null>(null);
    const [userData, setuserData] = useState<any | null>(null);
    const [groups, setgroups] = useState<any | null>([]);
    const [selectedGroup, setselectedGroup] = useState<any | null>(null);
    const [forms, setforms] = useState<any | null>([]);
    const [action, setaction] = useState<any | "">("");
    const [formData, setformData] = useState<any | null>(null);
    const [formCount, SetFormCount] = useState(0);
    const [popupType, setpopupType] = useState("");

    const tabData = [
        {
            title: "Project Details",
            id: 1,
            tab: "project details"
        },
        {
            title: "Study Design",
            id: 2,
            tab: "study design"
        },
        {
            title: "Data Collection",
            id: 3,
            tab: "Data collection"

        },
        {
            title: "Data Analysis",
            id: 4,
            tab: "Data analysis"
        },
        {
            title: "Mentors",
            id: 5,
            tab: "mentors"

        },
        {
            title: "Registered Events",
            id: 6,
            tab: "register events"

        },
    ];

    useEffect(() => {
        if (location.state) {
            let tempState: any = location.state;
            setprojectData(tempState);
            let temp: any = localStorage.getItem("coguide_user");
            if (temp) {
                temp = JSON.parse(temp);
                setuserData(temp);
                fetchGroups(tempState.TopicID || tempState.TopicCode);
            }
        } else {
            navigate("/project");
        }
    }, [location.state, navigate]);


    /**
     * columns
     */
    const columns = [
        {
            id: "form_name",
            label: "Form Name",
            accessor: "form_name",
            sort: "asc",
        },
        {
            id: "total_responses",
            label: "Total Records",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <>
                        <span>{original.total_responses}</span>
                        {!original.survey_form && (
                            <span className="add-icon">
                                <i className="icon icon-add_circle"></i>
                                <span className="icon-text">Add</span>
                            </span>
                        )}
                    </>
                );
            },
        },
        {
            id: "variables",
            label: "Variables",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <span className="add-icon" >
                        <span className="icon-text">Update</span>
                    </span>
                );
            },
        },
        {
            id: "survey",
            label: "Survey",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return original.survey_form ? (
                    <i
                        className="icon icon-copy"
                    ></i>
                ) : (
                    <>-</>
                );
            },
        },
        {
            id: "view",
            label: "View",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <i
                        className="icon icon-eye"
                    ></i>
                );
            },
        },
        {
            id: "edit",
            label: "Edit",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <i
                        className="icon icon-edit"
                    ></i>
                );
            },
        },
        {
            id: "delete",
            label: "Delete",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <i
                        className="icon icon-delete"
                    ></i>
                );
            },
        },
    ];

    /**
     * fetch groups data
     */
    const fetchGroups = async (id) => {
        try {
            dispatch(setLoading(true));
            let tempGroups = await getGroups(id);
            tempGroups?.data.forEach((obj) => {
                obj.label = obj.name;
                obj.value = obj.id;
            });
            setgroups(tempGroups?.data || []);
            let groupIndex = tempGroups?.data.findIndex((obj) => obj.is_default);

            dispatch(setLoading(false));
        } catch (e: any) {
            setgroups([]);
            dispatch(setLoading(false));
        }
    };

    /**
     * onclick Action
     */
    const onAction = (type, data) => {
        if (type == 6) {
            setpopupType("group")
        } else {
            setpopupType("analyse")
        }
    }


    const onClose = () => {
        setpopupType("");
            
    let mon = monaco.languages.registerCompletionItemProvider("coguideLanguage", {
        provideCompletionItems: (model, position) => {
          return {
            suggestions: [],
          };
        }
      })
       mon.dispose();
    }
    return (
        <>
            {popupType == "group" ?
                <GroupVariable data={projectData} {...props} onClose={() => onClose()} />
                : ""
            }
            <div className="data-analysis-container">
                <Row className="page-header m-0">
                    <Col className="p-0 project-title" md={12}>
                        <ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => navigate("/project")} />
                        {projectData?.TopicDesc}
                    </Col>
                    <Col className="p-0"></Col>
                </Row>
                <Tabs data={tabData} selectedTab={3} projectData={projectData} />

                <div className="table-container">

                    <CustomTable
                        header="Records"
                        data={forms}
                        tableProps={columns}
                        isSearch={true}
                        isFilter={false}
                        isView={false}
                        isGroup={true}
                        onAction={(type, data = null) => onAction(type, data)}
                    ></CustomTable>

                </div>
            </div>
        </>
    )
}