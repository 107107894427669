import AjaxUtil from '../../axios/axios';

export const getGroups = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`groups?topic_id=${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const searchGroup = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`groups?key=`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getRoles = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`roles`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getUsers = async(projectId, id) => {
    try {
        return await AjaxUtil.sendRequest(`team/${projectId}/${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const createGroup = async() => {
    try {
        let data = {
            "name": "Group 1",
            "description": "Group 1",
            "topicid": "C4215",
            "group_type": "test_group"
        }
        return await AjaxUtil.sendRequest(`group`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}


export const deleteGroup = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`group/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getForms = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`forms/${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getFormData = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`form/${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const createForm = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`form`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateForm = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`form`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const deletForm = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`form/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getVariables = async(keyword, page) => {
    try {
        return await AjaxUtil.sendRequest(`variables/search?key=${keyword}&page=${page}&per_page=20`, {}, false, { method: 'get', showError: true, isProfile: true, isHeaders: true} );
    } catch (error) {
        throw error;
    }
}

export const getVariableTypes = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`variable/types`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getVariableValidations = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`variable/validations`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const createVariable = async(data, formId) => {
    try {
        return await AjaxUtil.sendRequest(`form/${formId}/variable`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getVariable = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`variable/${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateVariable = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`variable`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const deleteVariable = async(formId, variableId) => {
    try {
        return await AjaxUtil.sendRequest(`variable/${formId}/${variableId}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getFormVariables = async(formId) => {
    try {
        return await AjaxUtil.sendRequest(`form/${formId}/variables`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const arrangeCategories = async(formId, data) => {
    try {
        return await AjaxUtil.sendRequest(`form/${formId}/variables`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const onAction = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`form/action`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getFormRecords = async(formId, page, size) => {
    try {
        return await AjaxUtil.sendRequest(`form/${formId}/responses?page=${page}&per_page=${size}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const deleteRecord = async(recordId) => {
    try {
        return await AjaxUtil.sendRequest(`form/response/${recordId}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const uploadRecord = async(formData) => {
    try {
        return await AjaxUtil.sendRequest(`form/responses/upload`, formData, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const downloadFieldTemplate = async(formId) => {
    // const headers =  headers: {'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}, responseType: "arraybuffer"}
    try {
        return await AjaxUtil.sendRequest(`form/${formId}/variables/download`, {}, false, { method: 'get', showError: true, isProfile: true,responseType: "arraybuffer"} );
    } catch (error) {
        throw error;
    }
}

export const downloadFormData = async(formId) => {
    // const headers =  headers: {'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}, responseType: "arraybuffer"}
    try {
        return await AjaxUtil.sendRequest(`form/${formId}/data/download`, {}, false, { method: 'get', showError: true, isProfile: true,responseType: "arraybuffer"} );
    } catch (error) {
        throw error;
    }
}

export const downloadAnalyticalData = async(topicId) => {
    // const headers =  headers: {'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}, responseType: "arraybuffer"}
    try {
        return await AjaxUtil.sendRequest(`ad/download/${topicId}`, {}, false, { method: 'get', showError: true, isProfile: true,responseType: "arraybuffer"} );
    } catch (error) {
        throw error;
    }
}

export const getNonSurveyForm = async(formId) => {
    let versionid  = Number(localStorage.getItem("versionid"));

    try {
        return await AjaxUtil.sendRequest(`form/${formId}/datacollect?version_id=${versionid}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const submitIdentifierForm = async(reqObj) => {
    try {
        return await AjaxUtil.sendRequest(`form/submit`, reqObj, false, {method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateIdentifierForm = async(reqObj) => {
    try {
        return await AjaxUtil.sendRequest(`form/response`, reqObj, false, {method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * validate the data entry form data
 * @param {*} formId 
 * @param {*} reqObj 
 * @returns 
 */
export const validateIdentifierForm = async(formId, reqObj) => {
    try {
        if(formId == ""){
            return await AjaxUtil.sendRequest(`variable/validate`, reqObj, false, { method: 'post', showError: true, isProfile: true} );

        }else{
            return await AjaxUtil.sendRequest(`variable/validate?source_form_id=${formId}`, reqObj, false, { method: 'post', showError: true, isProfile: true} );

        }
    } catch (error) {
        throw error;
    }
}

export const fetchNextVariable = async(formId, fieldId, answer) => {
    let versionid = localStorage.getItem("versionid");

    try {
        return await AjaxUtil.sendRequest(`variable/next?form_id=${formId}&field_id=${fieldId}&answer=${answer}&version_id=${versionid}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * update strict mode
 */
export const updateStrictMode = async(id,data) => {
    try {
        return await AjaxUtil.sendRequest(`form/${id}/lock/toggle`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get tree data
 */
export const getTreeData = async(formid,versionid) => {
    try {
        return await AjaxUtil.sendRequest(`/variable/flows?form_id=${formid}&version_id=${versionid}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}