import { CheckCircleOutlined, CloseCircleOutlined, LinkOutlined, SnippetsOutlined } from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, CardTitle } from "reactstrap";
import { CurrentUser, setLoading } from '../../store/slices/auth';
import { getProfileDetails } from '../profile/apiController';
import { sendUserInvite, updateInvitesStatus } from "./apiController";
import { RejectedModal } from "./RejectedModal";
import { showToaster, toasterTypes } from "../../widgets";
import dayjs from 'dayjs';



export default function PeopleProfile(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [profile, setprofile] = useState<any | null>(null);
    const location = useLocation();
    const [userId, SetUserId] = useState<any>(location.state);
    const [popUpType, SetPopUpType] = useState("");
    const [inviteSent,SetInviteSent] = useState(false);
    // get userId
    //let userId = location?.state;
    //  const value = userid?.state?.id;
    //const {id} = state.id;
    useEffect(() => {
        let inviteDetail: any = location.state;
        let uid = inviteDetail.userid || inviteDetail.inviter_id;
        let userid = { userid: uid}
		console.log(userId)
        fetchProfileDetails(userid);
    }, []);

    /**
     * fetch profile data
     */
    const fetchProfileDetails = async (userid) => {
        try {
            dispatch(setLoading(true));
            let response = await getProfileDetails(userid);
            setprofile(response);
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    function onReject(type: '') {
        SetPopUpType('');
    }


    const acceptInvite = async (id) => {
        try {
            dispatch(setLoading(true));
            let data = {
                status: "accepted"
            }
            let inviteDetail: any = location.state;
            let updated = await updateInvitesStatus(inviteDetail.id, data);
            if (updated['success'] == true) {
                showToaster(toasterTypes.SUCCESS, "Successfully accepted the invite")
                navigate('/project')
            }
            dispatch(setLoading(false));

        } catch (e: any) {
            dispatch(setLoading(false));
            throw e;
        }

    }

    /**
     * send invite
     */
    const sendInvite = async () => {
        try {
            dispatch(setLoading(true))
            const response = await sendUserInvite({ invitee_email: userId.data.emailid, invitee_role: userId.usertype, invitee_name: "", project_id: userId.projectId, invitee_id: userId.data.id });
            if (response.success) {
                SetInviteSent(true)
            }
            dispatch(setLoading(false))

        } catch (e: any) {
            throw e;
        }
    }

    return (
        <>

            <div className="people-profile-container">
                <div className="people-profile-head">
                    <Row className="row-work">
                        <Col className='p-0 d-flex m-0' md={10} xs={12}>
                            <div className='profile-data'>
                                {profile?.photo_link ?
                                    <div className='profile-container'>
                                        <img className='profile-image' alt='Profile' src={profile.photo_link} /> </div> :
                                    <div className='profile-container'>
                                        <img className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                                    </div>
                                }
                                <div className='d-flex m-1 align-items-center'>

                                    <div className='name-container'>
                                        {!profile?.profile_title && <div className='greet-text'>Hello</div>}
                                        {/* <div className='title-text'>{profile?.profile_title}</div> */}

                                        <div className='first-text'>{profile?.first_name}<span className='last-text'>{profile?.last_name}</span></div>

                                    </div>
                                    <div className='info-container'>
                                        <span className='mobile-text'>{profile?.mobilenumber}</span>
                                        <span className='email-text'>{profile?.emailid}</span>
                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col className='p-0 invite-btn-col' md={2} xs={12}>
                            {userId.pagetype == "invite" ?
                                <Button className="invite-btn" onClick={() => sendInvite()} disabled={inviteSent}>{inviteSent == true ? "Invitation sent" :"Invite"}</Button>
                                :
                                <>
                                    {
                                        popUpType == "reject" ?
                                            <RejectedModal {...props} onClose={(type) => onReject(type)} />
                                            : ""
                                    }
                                    <CloseCircleOutlined className="reject" style={{ color: "red", fontSize: "32px", margin: "0px 5px" }} onClick={() => SetPopUpType("reject")} />
                                    <CheckCircleOutlined className="accept" style={{ color: "darkgreen", fontSize: "32px" }} onClick={() => acceptInvite("")} />
                                </>
                            }

                        </Col>
                    </Row>
                </div>
                <div className='page-scroll'>
						<Row>
							<Col md={5}>
								<div className='card-container'>
									<Row className='m-0 mb-3'>
										<Col className='p-0'>
											<div className='card-header-text'>Contact Details</div>
										</Col>
									
									</Row>
									{profile && Object.keys(profile?.contact_details).length !== 0 &&
										<div className='contact-details'>
											<div>
												<i className='icon-email'></i>
												<span className='profile-data'>{profile?.contact_details?.email}</span>
											</div>
											<div>
												<i className='icon-phone'></i>
												<span className='profile-data'>{profile?.contact_details?.phone && profile?.contact_details?.phone.replace(" ","-")}</span>
											</div>
											{profile?.contact_details?.work_email &&
												<div className='mt-2'>
													<i className='icon-email'></i>
													<span className='profile-data'>{profile?.contact_details?.work_email}</span>
												</div>
											}
											{profile?.contact_details?.work_phone && profile?.contact_details?.work_phone.replace(" ","").length > 0 ? 
												<div>
													<i className='icon-phone'></i>
													<span className='profile-data'>{profile?.contact_details?.work_phone && profile?.contact_details?.work_phone.replace(" "," ")}</span>
												</div>
												:""
											}
										</div>
									}
								</div>
							</Col>
							<Col md={7}>
								<div className='card-container'>
									<Row className='m-0 mb-3'>
										<Col className='p-0'>
											<div className='card-header-text'>Address</div>
										</Col>
					
									</Row>
									{profile?.address_details?.map((obj, index) => (
										<Row key={index}>
											<Col md={10} className='mt-2'>
												{/* <div className='address-type'>{profile?.address_details?.length === 1 ? obj.address_type : obj.address_type}</div> */}
												<div className='address-text'>
													<span className='pin-text'>{obj.pincode} </span>
													{obj.address_text}, {obj.city}, {obj.state}, {obj.country}
												</div>
											</Col>
											
										</Row>
									))}
								</div>
							</Col>
						</Row>
						<div className='card-container'>
							<Row className='m-0 mb-3'>
								<Col className='p-0'>
									<div className='card-header-text'>Skill Set & Certifications</div>
								</Col>
							
							</Row>
							{profile && profile?.skills?.map((obj, index) => (
								<Row key={index} className='m-0 mt-2 skill-container'>
									<Col md={4} className='p-0'>
										<div className='skill-data'>
											<div className='logo-border'>
												<img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
											</div>
											<div>
												<div className='skill-name'>{obj.skill_name}</div>
												<div className='skill-rating'>Skill rating <span className='skill-value'>{obj.self_rating}</span><span className='total-rating'> / 5</span></div>
											</div>
										</div>
									</Col>
									<Col md={6} className='p-0'>
										<div className='m-0 align-item skills'>
											{obj.certifications && (
												obj?.certifications.map((subObj, subIndex) => (
													<div className='p-0 skillset-cert' key={subIndex}>
														<div className='pl-3'>
															<div className='certification-name'>{subObj.certification_name}</div>
															{subObj.certification_url ? <div className="link" onClick={() => window.open(subObj.certification_url)}><LinkOutlined /><div className='link-text-cert'>{subObj.certification_url.replace(" ", "")}</div></div> : ""}
															<div className='certification-institute'><SnippetsOutlined />{subObj.organisation}</div>
															<div className='certification-date'>{subObj.issued_month ? "Issued : " + subObj.issued_month.match(/[a-zA-Z]/g) ? subObj.issued_month + " " + subObj.issued_year : moment(subObj.issued_month + "/" + subObj.issued_year, "DD MM yyyy").format('DD-MMM-YYYY') : ""}</div>
														</div>
													</div>
												))
											)}
										
										</div>
									</Col>
								
								</Row>
							))}
						</div>
						<div className='card-container'>
							<Row className='m-0 mb-3'>
								<Col className='p-0'>
									<div className='card-header-text'>Work Experience</div>
								</Col>
							
							</Row>
							{profile && profile?.work_details?.map((obj, index) => (
								<Row key={index} className='m-0 mt-2 skill-container'>
									<Col md={4} className='p-0'>
										<div className='skill-data'>
											<div className='logo-border'>
												<img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
											</div>
											<div>
												<div className='skill-name'>{obj.company_name}, <span className='job-title'>{obj.job_title}</span></div>
												{/* <div className='total-rating'>{obj.job_description}</div> */}
												<div className='skill-rating'>{moment(obj.start_month + ' ' + obj.start_year).format('DD MMM yyyy') != "Invalid date" ? obj?.start_month && obj.start_month.match(/[a-zA-Z]/g) ? obj.start_month + obj.start_year : moment(obj.start_month + '/' + obj.start_year, "DD/MM/yyyy").format('DD MMM yyyy') : "" || moment(obj.start_month + '/' + obj.start_year, "DD/MM/YYYY").format('DD MMM yyyy')} {" to "} 
												{obj.end_month === 'till_date' ? 'Till Date' : obj.end_month.match(/[a-zA-Z]/g) ? obj.end_month + obj.end_year : moment(obj.end_month + '/' + obj.end_year, "DD/MM/YYYY").format('DD MMM yyyy')}</div>
												<div className='skill-rating'>{obj?.location?.city}, {obj?.location?.state}, {obj?.location?.country}</div>
											</div>
										</div>
									</Col>
									<Col md={6} className='p-0'>
										<div className='skill-data flex-column align-items-start'>
											<div className='skill-rating'>Skills / Description / Activites / Links</div>
											<div className='row m-0'>
												{obj.skills?.map((subObj, subIndex) => (
													<div className='activity-container' key={subIndex}>
														<div className='activity-text'>{subObj.skill_name}</div>
													</div>
												))}
											</div>
											<div className='total-rating'><a href={obj.documents} target='_blank'>{obj.documents}</a></div>
										</div>
									</Col>
								
								</Row>
							))}
						</div>
						<div className='card-container'>
							<Row className='m-0 mb-3'>
								<Col className='p-0'>
									<div className='card-header-text'>Education</div>
								</Col>
								
							</Row>
							{profile?.education_details?.map((obj, index) => (
								<Row key={index} className='m-0 mt-2 skill-container'>
									<Col md={4} className='p-0'>
										<div className='skill-data'>
											<div className='logo-border'>
												<img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
											</div>
											<div>
												<div className='skill-name'>{obj.institute}</div>
												<div className='total-rating'>{obj.degree} in {obj.field_of_study}</div>
												<div className='total-rating'>{obj.description}</div>
												<div className='skill-rating'>{moment(obj.start_month + ' ' + obj.start_year).format('DD MMM yyyy') != "Invalid date" ? obj.start_month && obj.start_month.match(/[a-zA-Z]/g) ? obj.start_month + ' ' + obj.start_year : moment(obj.start_month + '/' + obj.start_year, "DD/MM/yyyy").format('DD MMM yyyy') : "" || obj.start_month && obj.start_month.match(/[a-zA-Z]/g) ? obj.start_month + ' ' + obj.start_year : moment(obj.start_month + '/' + obj.start_year, "DD/MM/YYYY").format('DD MMM yyyy')}  
												{" to "}{obj.end_month === 'till_date' ? 'Till Date' : obj.end_month && obj.end_month.match(/[a-zA-Z]/g) ? obj.end_month + ' ' + obj.end_year : moment(obj.end_month + '/' + obj.end_year, "DD/MM/YYYY").format('DD MMM yyyy')}</div>
												<div className='skill-rating'>{obj?.location?.city}, {obj?.location?.state}, {obj?.location?.country}</div>
											</div>
										</div>
									</Col>
									<Col md={6} className='p-0'>
										<div className='skill-data flex-column align-items-start'>
											<div className='skill-rating'>Skills / Description / Activites / Links</div>
											<div className='row m-0'>
												{obj.activities.map((subObj, subIndex) => (
													<div className='activity-container' key={subIndex}>
														<div className='activity-text'>{subObj}</div>
													</div>
												))}
											</div>
											<div className='total-rating'>{obj.documents}</div>
										</div>
									</Col>
									
								</Row>
							))}
						</div>
						<Row>
							<Col md={12}>
								<div className='card-container'>
									<Row className='m-0 mb-3'>
										<Col className='p-0'>
											<div className='card-header-text'>Awards</div>
										</Col>
									
									</Row>
									{profile?.award_details?.map((obj, index) => (
										<Row key={index} className='m-0 mt-2 skill-container'>
											<Col md={10} className='p-0'>
												<div className='skill-data'>
													<div className='logo-border'>
														<img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
													</div>
													<div>
														<div className='skill-name'>{obj.award_name}</div>
														{obj.award_url && <div className='link-text' onClick={() => window.open(obj.award_url)}>{obj.award_url}</div>}
														<div className='total-rating'>{obj.award_description}</div>
													</div>
												</div>
											</Col>
											
										</Row>
									))}
								</div>
							</Col>

						</Row>
						<div className='card-container'>
							<Row className='m-0'>
								<Col className='p-0'>
									<div className='card-header-text'>Research Profiles</div>
								</Col>
							
							</Row>


							<Row key={0} className='m-0 mt-2 skill-container'>
								<Col md={10} className='p-0'>
									<div className='skill-data'>
										{/* <span className='publication_content'>Orcid ID:  {profile.research_experience.orcid}</span> */}
										<span className='publication_content'>H Index:  {profile && profile.research_experience.h_index}</span>
										<span className='publication_content'>I 10 Index:  {profile && profile.research_experience.i10_index}</span>
										<span className='publication_content'>Patents:  {profile && profile.research_experience.patent_count}</span>
										<span className='publication_content'>Publications: {profile && profile.research_experience.publication_count}</span>
										<span className='publication_content'>Projects:  {profile && profile.research_experience.projects_count}</span>
									</div>
								</Col>
							
							</Row>

							<Row key={0} className='m-0 mt-2 skill-container'>
								<Col md={12}>
									<div>
										<Row className='m-0 mb-3'>
											<Col className='p-0'>
												<div className='card-header-text'>Profile Links</div>
											</Col>
										
										</Row>
										{profile?.research_profiles?.map((obj, index) => (
											<Row key={index} className='m-0 mt-2 skill-container'>
												<Col md={10} className='p-0'>
													<div className='skill-data'>
														<div className='logo-border'>
															<img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
														</div>
														<div>
															<div className='skill-name'>{obj.profile_type}</div>
															{obj.profile_link && <div className='link-text' onClick={() => window.open(obj.profile_link)}>{obj.profile_link}</div>}
														</div>
													</div>
												</Col>
					
											</Row>
										))}
									</div>
								</Col>
							</Row>
						</div>

						<div className='card-container'>
							<Row className='m-0'>
								<Col className='p-0'>
									<div className='card-header-text'>Research Works</div>
								</Col>
							
							</Row>

							{profile?.research_project?.map((obj, index) => (
								<Row key={0} className='m-0 mt-2 research-work-container'>
									<Col md={10} className='p-0'>
										<div className='skill-data'>
											<div className='logo-border'>
												<img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
											</div>
											<div>

												<div className='project-name'>
													<span>{obj.project_name}</span>
													<span style={{ color: "#2F2E40", fontSize: "14px" }}> - </span>
													<span className="pub-type">{obj.publication_type?.label}</span>
												</div>

												{obj.authors && <div className='project-descp'><span style={{ color: "#ccc", fontSize: "14px" }}>Authors:</span> {obj.authors.map((item, index) => {
													return <span>{item}{index + 1 != obj.authors.length ? ", " : ""} </span>
												})}

												
												<div className="other-detail">
													{obj.journal ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>Journal Name:</span> {obj.journal} <span style={{ color: "#ccc", fontSize: "14px" }}> | </span></span> : ""}
													{obj.publisher_name ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>Published By: </span>{obj.publisher_name} <span style={{ color: "#ccc", fontSize: "14px" }}> | </span> </span> : ""}
													{obj?.published_date ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>Year: </span>{dayjs(obj?.published_date, "YYYY-MM-DD HH:mm:ss").format("YYYY")}<span style={{ color: "#ccc", fontSize: "14px" }}> | </span></span> : ""}
													{obj.volume ? <span> <span style={{ color: "#ccc", fontSize: "14px" }}>Volume: </span>{obj.volume} <span style={{ color: "#ccc", fontSize: "14px" }}> | </span></span> : ""}
													{obj.issue ? <span> <span style={{ color: "#ccc", fontSize: "14px" }}>Issue: </span>{obj.issue} <span style={{ color: "#ccc", fontSize: "14px" }}> | </span></span> : ""}
													{obj.page_no ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>PageNo.: </span>{obj.page_no}<span style={{ color: "#ccc", fontSize: "14px" }}> | </span> </span> : ""}
													{obj.place ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>Place.: </span>{obj.place} </span> : ""}
												</div>
												{obj.doi ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>DOI: </span><a target="_blank" href={obj.doi}>{obj.doi}</a> </span> : ""}
												</div>
												}
												{/* <div className='publish-date'>{obj?.published_date && expectedDateFormat(obj?.published_date, 'DD MMM YYYY', 'YYYY-MM-DD')}</div> */}
												{obj.project_status.label == "Published" ?
													<div><span className='project-status-completed'>{obj.project_status.label}</span>{obj.url ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>| URL: </span><a target="_blank" href={obj.url}>{obj.url}</a></span> : ""}</div>
													:
													<div className='project-status-progress'>{obj.project_status.label}</div>
												}

											</div>
										</div>
									</Col>
								
								</Row>
							))
							}

						</div>
					</div>
            </div>
        </>
    );
}