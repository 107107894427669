import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { setLoading } from '../../store/slices/auth';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';
import ModalComponent from '../common/Modal/modal';
import { arrangeCategories, getRoles, getUsers } from './apiController';
import { CreateFormElements } from './FormElements';

export default function AddPermissions(props) {
    const dispatch = useDispatch();
    const [variables, setvariables] = useState(props.variables);
    const [selectedVariable, setselectedVariable] = useState(0);
    const [permissions, setpermissions] = useState([{ role: null, users: [], user: '', accessType: [] }]);
	const [roles, setroles] = useState<any | []>([]);
    const initialValues = useMemo(() => ({
		searchField: ''
    }), []);
	const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(CreateFormElements, initialValues, { onValueChange });
    
    useEffect(() => {
		fetchRoles();
	}, []);

    const onSave = async() => {
        try {
            dispatch(setLoading(true));
            let permissionsData: any = [];
            variables.forEach(obj => {
                permissionsData.push({
                    "id": obj.id,
                    "field_role_access" : obj.field_role_access,
                    "field_user_access": obj.field_user_access
                });
            });
            let response = await arrangeCategories(props.formId, permissionsData);
            if(response.success) {
                showToaster(toasterTypes.SUCCESS, response.message);
                props.onClose('save');
            }
			dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
        // props.onClose('save');
    }

    function onValueChange (name, value, values, fieldValues=null) {
		return [{
			...values,
			[name]: value
		}]
    }

    const fetchRoles = async () => {
		try {
			dispatch(setLoading(true));
			let tempRoles = await getRoles();
			if(tempRoles && tempRoles.data && tempRoles.data.length > 0) {
				tempRoles.data.forEach((obj, index) => {
					obj.label = obj.name;
					obj.value = obj.name.toLowerCase();
					obj.index = index;
					obj.users = [];
				});
                let temp: any = [];
                variables[selectedVariable]?.field_role_access?.forEach((roleObj) => {
                    let tempPermissions = tempRoles.data.filter((obj) => obj.value === roleObj.role_name);
                    temp.push({ role: tempPermissions[0], users: [], user: '', accessType: roleObj.permissions });
                });
                if(variables[selectedVariable]?.field_user_access && variables[selectedVariable]?.field_user_access.length > 0) {
                    variables[selectedVariable]?.field_user_access?.forEach(async(userObj, userIndex) => {
                        let tempPermissions = tempRoles.data.filter((obj) => obj.value === userObj.role_name);
                        userObj.label = userObj.email;
                        userObj.value = userObj.user_number;
                        let roleIndex = tempRoles?.data.findIndex((obj) => obj.value === userObj.role_name);
                        if(roleIndex >= 0) {
                            try {
                                dispatch(setLoading(true));
                                let tempUsers = await getUsers(props.projectData.TopicCode || props.projectData.TopicID, userObj.role_name);
                                if(tempUsers?.data && tempUsers.data.length > 0) {
                                    tempUsers.data.forEach((obj) => {
                                        obj.label = obj.emailid;
                                        obj.value = obj.usernumber;
                                    });
                                    tempRoles.data[roleIndex].users = tempUsers.data;
                                    temp.push({
                                        role: tempPermissions[0],
                                        users: tempUsers.data,
                                        user: userObj, accessType: userObj.permissions
                                    });
                                } else {
                                    temp.push({
                                        role: tempPermissions[0],
                                        users: [],
                                        user: userObj, accessType: userObj.permissions
                                    });
                                }
                                dispatch(setLoading(false));
                            } catch (e: any) {
                                temp.push({
                                    role: tempPermissions[0],
                                    users: [],
                                    user: userObj, accessType: userObj.permissions
                                });
                                dispatch(setLoading(false));
                            }
                        }
                        if(userIndex + 1 === variables[selectedVariable]?.field_user_access.length) {
                            if(temp.length === 0) {
                                temp = [{ role: null, users: [], user: '', accessType: [] }];
                            }
                            setpermissions(temp);
                            setroles(tempRoles.data);
                        }
                    });
                } else {
                    if(temp.length === 0) {
                        temp = [{ role: null, users: [], user: '', accessType: [] }];
                    }
                    setpermissions(temp);
                    setroles(tempRoles.data);
                }
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			setroles([]);
			dispatch(setLoading(false));
		}
	}

    const fetchUsers = async (roleData, roleIndex, tempPermissions, perIndex) => {
		if(roleData[roleIndex].users.length === 0) {
			try {
				dispatch(setLoading(true));
				let tempUsers = await getUsers(props.projectData.TopicCode || props.projectData.TopicID, roleData[roleIndex].label.toLowerCase());
				if(tempUsers?.data && tempUsers.data.length > 0) {
					tempUsers.data.forEach((obj) => {
						obj.label = obj.emailid;
						obj.value = obj.usernumber;
					});
					roleData[roleIndex].users = tempUsers.data;
					tempPermissions[perIndex].users = tempUsers.data;
				}
				setpermissions(tempPermissions);
				setroles(roleData);
				dispatch(setLoading(false));
			} catch (e: any) {
				setroles(roleData);
				setpermissions(tempPermissions);
				dispatch(setLoading(false));
			}
		} else {
			tempPermissions[perIndex].users = roleData[roleIndex].users;
			setroles(roleData);
			setpermissions(tempPermissions);
		}
	}
    
    function changePermissions(name, value, index) {
		let temp: any = cloneDeep(permissions);
		if(name === 'role') {
			temp[index].user = null;
			temp[index].users = [];
			temp[index][name] = value;
			fetchUsers(roles, value.index, temp, index);
		} else if(name === 'user') {
			let isUser = false;
			temp.forEach((obj) => {
				if(obj.user && obj.user.usernumber === value.usernumber) {
					isUser = true;
					return;
				}
			});
			if(isUser) {
				showToaster(toasterTypes.ERROR, 'User is already added');
			} else {
				temp[index][name] = value;
			}
			setpermissions(temp);
		} else {
			temp[index][name] = value;
			setpermissions(temp);
		}
	}

    function changeVariable(index) {
        let form_role_access: any = [];
        let form_user_access: any = [];
        permissions.forEach((obj: any) => {
            if (obj.user && obj.user.value && obj.user.label && (obj.user.userrole || obj.user.role_name) && obj.accessType && obj.accessType.length > 0) {
                form_user_access.push({
                    "user_number": obj.user.value,
                    "email": obj.user.label,
                    "role_name": obj.user.userrole || obj.user.role_name,
                    "permissions": obj.accessType
                })
            } else if(obj.role?.value && obj.accessType && obj.accessType.length > 0) {
                form_role_access.push({
                    "role_name": obj.role.value,
                    "permissions": obj.accessType
                })
            }
        });
        variables[selectedVariable].field_user_access = form_user_access;
        variables[selectedVariable].field_role_access = form_role_access;
        setvariables(variables);
        setselectedVariable(index);
        let temp: any = [];
        if(variables[index].field_role_access && variables[index].field_role_access.length > 0) {
            variables[index].field_role_access.forEach((roleObj) => {
                let tempPermissions = roles.filter((obj) => obj.value === roleObj.role_name);
                temp.push({ role: tempPermissions[0], users: [], user: '', accessType: roleObj.permissions });
            });
            if(!variables[index].field_user_access || variables[index].field_user_access?.length === 0) {
                if(temp.length === 0) {
                    temp = [{ role: null, users: [], user: '', accessType: [] }];
                }
                setpermissions(temp);
            }
        } else if(!variables[index].field_user_access || variables[index].field_user_access?.length === 0) {
            if(temp.length === 0) {
                temp = [{ role: null, users: [], user: '', accessType: [] }];
            }
            setpermissions(temp);
        }
        if(variables[index].field_user_access && variables[index].field_user_access.length > 0) {
            variables[index].field_user_access?.forEach(async(userObj, userIndex) => {
                let tempPermissions = roles.filter((obj) => obj.value === userObj.role_name);
                userObj.label = userObj.email;
                userObj.value = userObj.user_number;
                let roleIndex = roles.findIndex((obj) => obj.value === userObj.role_name);
                if(roleIndex >= 0) {
                    try {
                        dispatch(setLoading(true));
                        let tempUsers = await getUsers(props.projectData.TopicCode || props.projectData.TopicID, userObj.role_name);
                        if(tempUsers?.data && tempUsers.data.length > 0) {
                            tempUsers.data.forEach((obj) => {
                                obj.label = obj.emailid;
                                obj.value = obj.usernumber;
                            });
                            roles[roleIndex].users = tempUsers.data;
                            temp.push({
                                role: tempPermissions[0],
                                users: tempUsers.data,
                                user: userObj, accessType: userObj.permissions
                            });
                        } else {
                            temp.push({
                                role: tempPermissions[0],
                                users: [],
                                user: userObj, accessType: userObj.permissions
                            });
                        }
                        dispatch(setLoading(false));
                    } catch (e: any) {
                        temp.push({
                            role: tempPermissions[0],
                            users: [],
                            user: userObj, accessType: userObj.permissions
                        });
                        dispatch(setLoading(false));
                    }
                }
                if(userIndex + 1 === variables[index]?.field_user_access.length) {
                    if(temp.length === 0) {
                        temp = [{ role: null, users: [], user: '', accessType: [] }];
                    }
                    setpermissions(temp);
                }
            });
        }
    }

	const addUser = () => {
		let temp: any = cloneDeep(permissions);
		temp.push({ role: roles[0], users: roles[0].users, user: '', accessType: ['r'] });
		setpermissions(temp);
	}

    return (
        <ModalComponent
            width="800px"
            isOpen={true}
            onClose={() => props.onClose()}
            header="Permissions"
            centered={true}
            customClass='profile-modal permission-modal'
            body={
                <div>
                    <Row className='m-0'>
                        <Col className='p-0 variables-border' md={3}>
                            <Row className='m-0 align-items-center pt-3'>
                                <Col className='p-0' md={4}>
                                    <div className='variables-header'>Variables</div>
                                </Col>
                                <Col className='p-0 pr-4'>
                                    <FieldItem
                                        {...CreateFormElements.searchField}
                                        value={values.searchField}
                                        onChange={(...e) => handleChange(CreateFormElements.searchField.name, ...e)}
                                    />
                                </Col>
                            </Row>
                            <div className='variables-scroll'>
                                {variables.map((obj, index) => (
                                    <Row key={index} className='m-0 variables-row' onClick={() => changeVariable(index)}>
                                        <Col className='p-0' md={10}>
                                            <div className='variable-text'>{obj.field_name}</div>
                                        </Col>
                                        <Col className='p-0 text-right' md={2}>
                                            {((obj.field_role_access && obj.field_role_access.length > 0) || (obj.field_user_access && obj.field_user_access.length > 0))&&
                                                <i className='icon icon-permission' />
                                            }
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        </Col>
                        <Col className='p-0 variable-details'>
                            <Row className='m-0'>
                                <Col className='p-0'>
                                    <div className='variable-name'>{variables[selectedVariable].field_name}</div>
                                </Col>
                                <Col className='p-0'>
                                    <div className='user-text' onClick={() => addUser()}>Add User</div>
                                </Col>
                            </Row>
                            {roles.length > 0 && (
                                permissions.map((obj: any, index) => (
                                    <Row className='m-0 permissions-row' key={index}>
                                        <Col md="3">
                                            <FieldItem
                                                {...CreateFormElements.role}
                                                value={obj.role}
                                                values={roles}
                                                onChange={(...e) => changePermissions(CreateFormElements.role.name, ...e, index)}
                                            />
                                        </Col>
                                        <Col md="4">
                                            <FieldItem
                                                {...CreateFormElements.user}
                                                value={obj.user}
                                                values={obj.users}
                                                onChange={(...e) => changePermissions(CreateFormElements.user.name, ...e, index)}
                                            />
                                        </Col>
                                        <Col md="5">
                                            <FieldItem
                                                {...CreateFormElements.accessType}
                                                value={obj.accessType}
                                                onChange={(...e) => changePermissions(CreateFormElements.accessType.name, ...e, index)}
                                            />
                                        </Col>
                                    </Row>
                                )
                            ))}
                        </Col>
                    </Row>
                </div>
            }
            footer={
				<div>
					<CustomButton type="primary" text="Save" onClick={() => onSave()}></CustomButton>
				</div>
			}
        />
    )
}
