import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { authSelector } from '../../store/slices/auth';
import { expectedDateFormat } from '../../utils/Utils';

export default function ProjectCardMentor(props) {
	const { currentUser } = useSelector(authSelector);
	const navigate = useNavigate();
	const [dataCollection, SetdataCollection] = useState<any>("")
	const [studyDesign, SetstudyDesign] = useState<any>("")

	const { data, type } = props;


	useEffect(() => {
		getPermissions();

	}, [])

	useEffect(() => {
		console.log(dataCollection)
	}, [dataCollection])

	const getPermissions = async () => {
		await data.permissions.map(items => {
			if (Object.keys(items)[0] == "Projects") {
				items.Projects.map(item => {
					if (item.permissions.filter(itm => itm.title == "allowed" && itm.is_selected == true).length != 0) {
						if (item.action == "data collections") {
							SetdataCollection("allowed")
							console.log("allowed")
						} else {
							SetstudyDesign("allowed")
							console.log(item.action)
						}
					} else {
						if (item.action == "data collection") {
							SetdataCollection("forbidden")
						} else {
							SetstudyDesign("forbidden")
						}
					}

				})
			}
			console.log(dataCollection)

		})

	}
	return (
		<div className='project-card'>
			<div className='top-row'>
				<Row className='m-0'>
					<Col className='p-0'>
						<div className='created-date'>{expectedDateFormat(data.createdon || data.UpdatedOn, 'DD MMM YYYY', 'YYYY-MM-DD')}</div>
					</Col>
					<Col className='p-0 row m-0 justify-content-end'>
						{
							dataCollection == "allowed" ?
								<div className='data-entry-button'>
									<div className='data-entry-text' onClick={() => props.onData()}>{type === 'projects' ? 'Data Entry' : 'Data Collection'}</div>
								</div>
								: ""
						}

						{/* {currentUser?.UserType !== 'Internal User' && <i className="icon icon-delete" onClick={() => props.onDelete()}></i>}
						<i className="icon icon-edit" onClick={() => props.onEdit()}></i> */}
					</Col>
				</Row>
				<div className='topic-desc' onClick={() => props.onprojectDetail()}>{data.topicdesc}</div>
				<div className='topic-scroll' onClick={() => props.onprojectDetail()}>
					{data.topicbigdesc?.map((obj, index) => (
						<div key={index} className='topic-objective'>{index + 1}. {obj}</div>
					))}
				</div>
			</div>
			<Row className='m-0 footer-row'>
				<Col className='p-0 add-people-text'>
					<span onClick={() => navigate('/people', { state: { projectid: data.id, type: "invitee" } })}>
						People
					</span>
				</Col>
				{type === 'projects' ?
					<Col className='p-0 text-right'>
						<i className="icon icon-study" onClick={() => props.onSampleSize(data)}></i>
						{data.Shared === 'y' && <i className="icon icon-share" onClick={() => props.onMove(data)}></i>}
					</Col> :
					<Col className='p-0 text-right'>
						{
							studyDesign == "allowed" ?
								<i className="icon icon-study" onClick={() => props.onSampleSize(data)}></i>
								: ""
						}
						<i className="icon icon-upload"></i>
					</Col>
				}
			</Row>
		</div>
	)
}
