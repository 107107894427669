import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Input } from "reactstrap";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import { CustomButton } from "../../widgets";
import CriteriaForm from "./CriteriaForm";
import { useLocation, useNavigate } from "react-router-dom";
import "./CreateCriteria.css";
import CustomTable from "../../widgets/table";



export default function CreateCriteria(props) {
  const [clicked, setClicked] = useState<any | "">("");
  const [showCriteriaDiv,setShowCriteriaDiv]=useState(false);
  const navigate = useNavigate();

  const FormElements = {
    search: {
      name: "search",
      placeholder: "Search",
      label: "",
      type: FIELD_TYPES.SEARCH,
      isMandatory: false,
    },
  };
  const [values, fields, handleChange] = useFieldItem(FormElements, {}, {});

  const onCriteria = () => {
    setClicked("4");
  };

  const closeCriteria = () => {
    navigate("/dataCollection");
  };

  const addData = (data) => {
    setShowCriteriaDiv(data);
  }

  return (
    <>
      {clicked == "4" && (
        <CriteriaForm {...props} addData={addData} onClose={() => setClicked("")} />
      )}

      <div className="profile-screen p-0">
        <div className="profile-header mx-2 d-flex justify-content-between">
          <h2 className="text-white d-flex">
            Criterias{" "}
           {
             showCriteriaDiv && <span>
             <CustomButton
               className="criteria-btn ml-5"
               type="alert-primary"
               text="Create"
               onClick={onCriteria}
             ></CustomButton>
           </span>
           }
          </h2>
          <i
            className="text-white icon icon-close"
            onClick={closeCriteria}
            style={{ fontSize: "28px", cursor: "pointer" }}
          ></i>
        </div>
      </div>

      <Row className="m-5 ">
        <Col className="p-0 d-flex m-0" md={6} xs={12}>
          <div className="project-header mr-4 pt-2">Criterias</div>
          <FieldItem
            {...FormElements.search}
            value={values.search}
            onChange={(...e) => handleChange(FormElements.search.name, ...e)}
            touched={fields.search && fields.search.hasError}
            error={fields.search && fields.search.errorMsg}
          />
        </Col>
      </Row>

      {
        showCriteriaDiv && <div className="row-cont">
        <Row className="upper-row">
          <span>
            <b>Criteria Name</b>
          </span>
          <div>
            <span className="mr-3">Source: Form1{}</span>
            <span className="mr-5">Destination: Form2{}</span>
            <span className="mr-3">
              <i className="icon icon-edit" onClick={() => ""}></i>
            </span>
            <span className="mr-5">
              <i className="icon icon-delete" onClick={() => ""}></i>
            </span>
          </div>
        </Row>
        <Row className="lower-row">b</Row>
      </div>
      }

      {!showCriteriaDiv && <div className="d-flex flex-column justify-content-md-center align-items-center ">
        <p>No Criterias available</p>
        <CustomButton
          className="mt-2"
          type="alert-primary"
          text="Create"
          onClick={onCriteria}
        ></CustomButton>
      </div> }
    </>
  );
}
