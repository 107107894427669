import React, { useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalComponent from "../common/Modal/modal";
import { CreateCriteriaElements } from "./FormElements";
import { setLoading } from "../../store/slices/auth";
import "./CriteriaForm.css";

export default function CriteriaForm(props) {
  const dispatch = useDispatch();
  const [action, setaction] = useState<any | "">("");
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const initialValues = useMemo(
    () =>
      props.previousState
        ? props.previousState
        : { name: "", source: "", destination: "" },
    [props.previousState]
  );
  const [values, fields, handleChange, { validateValues, updateValue }] =
    useFieldItem(CreateCriteriaElements, initialValues, { onValueChange });

  function onValueChange(name, value, values, fieldValues = null) {
    let resetValue = {};
    return [
      {
        ...values,
        ...resetValue,
        [name]: value,
      },
    ];
  }

  const onAdd = async () => {
    if (validateValues(["name", "source", "destination"])) {
      showToaster(
        toasterTypes.ERROR,
        "Please enter the proper values in the fields highlighted in red"
      );
    } else {
      props.onClose();
      props.addData(true);
      let reqObj: any = {
        // "topicid": props.projectData?.TopicID || props.projectData?.TopicCode,
        criteria_name: values.name,
        criteria_source: values.source,
        criteria_destination: values.destination,
      };
      if (props.isEdit) {
        reqObj.id = props.formData?.id;
      }
      try {
        dispatch(setLoading(true));
        let response: any;
        console.log("reqObj:::", reqObj);
        if (props.isEdit) {
          // response = await updateForm(reqObj);
        } else {
          // response = await createForm(reqObj);
        }
        dispatch(setLoading(false));
        showToaster(toasterTypes.SUCCESS, response.message);
        props.onFormCreation();
      } catch (e: any) {
        dispatch(setLoading(false));
      }
    }
  };

  const onAction = async (type, data: any = null) => {
    switch (type) {
      case 1:
        if (data?.total_responses === 0) {
          showToaster(toasterTypes.ERROR, "No Records Found");
        } else {
          dispatch(setLoading(true));
          // let response = await getFormData(data?.id);
          // setformData(response?.data);
          dispatch(setLoading(false));
          setaction(type);
        }
        break;
      case 2:
        try {
          dispatch(setLoading(true));
          // let response = await getFormData(data?.id);
          // setformData(response?.data);
          dispatch(setLoading(false));
          setaction(type);
        } catch (e: any) {
          // setgroups([]);
          dispatch(setLoading(false));
        }
        break;
      case 3:
        // setformData(data);
        setaction(type);
        break;
      default:
        setaction(type);
        break;
    }
  };

  return (
    <ModalComponent
      width="auto"
      onClose={() => props.onClose()}
      isOpen={true}
      header={<b>Create Criteria</b>}
      centered={true}
      customClass="profile-modal form-modal"
      body={
        <div>
          <Row>
            <Col md={12}>
              <FieldItem
                {...CreateCriteriaElements.name}
                value={values.name}
                onChange={(...e) =>
                  handleChange(CreateCriteriaElements.name.name, ...e)
                }
                touched={fields.name && fields.name.hasError}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FieldItem
                {...CreateCriteriaElements.source}
                value={values.source}
                onChange={(...e) =>
                  handleChange(CreateCriteriaElements.source.name, ...e)
                }
                touched={fields.source && fields.source.hasError}
              />
            </Col>
            <Col md={4}>
              <FieldItem
                {...CreateCriteriaElements.destination}
                value={values.destination}
                onChange={(...e) =>
                  handleChange(CreateCriteriaElements.destination.name, ...e)
                }
                touched={fields.destination && fields.destination.hasError}
              />
            </Col>
          </Row>
          <div className="permissions-border">
            <Row className="m-0">
              <Col className="p-0">
                <div className="permissions-text">Rules</div>
              </Col>
              <Col className="p-0">
                <div className="user-text" onClick={() => ""}>
                  Add Rules
                </div>
              </Col>
            </Row>
          </div>
          <div className="code-editor" onClick={() => setShowCodeEditor(true)}>
            <ModalComponent
              width="auto"
              onClose={() => setShowCodeEditor(false)}
              isOpen={showCodeEditor}
              header={
                <>
                  <b>Create Criteria </b>- Code Editor
                </>
              }
              centered={true}
              customClass="profile-modal form-modal code-editor-modal"
              body={<></>}
              footer={
                <Row className="affiliation-footer m-0">
                  <Col>
                    <CustomButton
                      className="justify-content-start"
                      type=""
                      isDisabled={false}
                      text="Reset"
                      onClick={() => console.log("clicked")}
                    ></CustomButton>
                  </Col>
                  <Col>
                    <CustomButton
                      type="primary"
                      isDisabled={false}
                      text="Add"
                      onClick={() => console.log("clicked")}
                    ></CustomButton>
                  </Col>
                </Row>
              }
            />
          </div>
        </div>
      }
      footer={
        <div>
          <CustomButton
            type="primary"
            isDisabled={false}
            text="Add"
            onClick={() => onAdd()}
          ></CustomButton>
        </div>
      }
    />
  );
}
