import { FIELD_TYPES } from "../../widgets/fields";

export const GroupFormElements = {
    grouptype: {
        name: "grouptype",
        placeholder: "Enter",
        label: "Grouping Type",
        values:[{value:"category_grouping",label:"Categorical"},
                {value:"numericgrouping",label:"Numerical"},
                {value:"Advanced_compute",label:"Compute"}],
        type : FIELD_TYPES.DROP_DOWN,
        isMandatory: true
    },
    formType: {
        name: "formtype",
        placeholder: "Enter",
        label: "Form",
        type : FIELD_TYPES.DROP_DOWN,
    },
    variable:{
        name: "variable",
        placeholder: "Enter",
        label: "Variable",
        type : FIELD_TYPES.DROP_DOWN,
    },
    newValue: {
        name: "newvalue",
        placeholder: "Enter",
        label: "",
        type : FIELD_TYPES.TEXT,
        isMandatory: true   
    },
    variablelist:{
        name: "variablelist",
        placeholder: "Variables",
        label: "",
        type : FIELD_TYPES.MULTI_SELECT,
        isMandatory: true   
    },
    newcode:{
        name: "newcode",
        placeholder: "New Variable Name",
        label: "",
        type : FIELD_TYPES.TEXT,
        isMandatory: true   
    },
    newcolumnname:{
        name: "newcolumnname",
        placeholder: "New Variable Name",
        label: "New Variable Name",
        type : FIELD_TYPES.TEXT,
        isMandatory: true   
    },
    codeeditor:{
        name: "codeeditor",
        placeholder: "",
        label: "",
        type : FIELD_TYPES.TEXT_AREA,
        isMandatory: true   
    }
}