import AjaxUtil from '../../axios/axios';

export const getSurveyFormData = async(formId) => {
    try {
        return await AjaxUtil.sendRequest(`survey/${formId}?version_id=${Date.now()}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * 
 * @param {*} formId 
 * @param {*} fieldId 
 * @param {*} answer 
 * @returns 
 */
export const getNextQuestion = async(formId, fieldId, answer) => {
    let versionid = localStorage.getItem("versionid");

    try {
        return await AjaxUtil.sendRequest(`survey/variable/next?form_id=${formId}&field_id=${fieldId}&answer=${answer}&version_id=${versionid}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const submitForm = async(reqObj) => {
    try {
        return await AjaxUtil.sendRequest(`survey/submit`, reqObj, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * Validation of survey form data
 * @param {*} formId 
 * @param {*} reqObj 
 * @returns 
 */
export const validateForm = async(formId, reqObj) => {
    try {
        if(formId == ""){
            return await AjaxUtil.sendRequest(`survey/variable/validate`, reqObj, false, { method: 'post', showError: true, isProfile: true} );

        }else{
            return await AjaxUtil.sendRequest(`survey/variable/validate?source_form_id=${formId}`, reqObj, false, { method: 'post', showError: true, isProfile: true} );

        }
    } catch (error) {
        throw error;
    }
}